import 'intersection-observer';

import PageContextProvider from '@components/context/PageContext';
import { ShopifyContextProvider } from '@components/context/Shopify';
import AgeGate from '@connected/AgeGate';
import Basket from '@connected/Basket';
import CookiePanel from '@connected/CookiePanel';
import Footer from '@connected/Footer';
import Header from '@connected/Header';
import NewsletterSignUpModal from '@connected/NewsletterSignUpModal';
import bgPaperTiled from '@img/paper-bg-tiled.jpg';
import bgLinesTiled from '@img/lines-bg-tiled.jpg';
import bgStressedTiled from '@img/stressed-bg-tiled.jpg';
import GlobalStyles from '@presentation/GlobalStyles';
import store from '@redux/store';
import { WindowLocation } from '@reach/router';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';

export interface LayoutProps {
  children: ReactNode;
  data: Record<string, unknown>;
  location: WindowLocation;
  pageContext: Record<string, unknown>;
  path: string;
}

const Layout: React.FC<LayoutProps> = ({ children, location, path, pageContext }) => {
  let useAlternateFooter = false;

  if (typeof pageContext.useAlternateFooter === 'boolean') {
    useAlternateFooter = pageContext.useAlternateFooter;
  }

  // Handle scrolling back to top when location changes.
  useEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
    });
  }, [location.key]);

  return (
    <Provider store={store}>
      <PageContextProvider context={pageContext}>
        <ShopifyContextProvider>
          <Helmet htmlAttributes={{ lang: 'en' }}>
            <link rel="preconnect" href="https://www.googletagmanager.com" />
            <link rel="preload" href={bgPaperTiled} as="image" />
            <link rel="preload" href={bgLinesTiled} as="image" />
            <link rel="preload" href={bgStressedTiled} as="image" />
            <link
              rel="preload"
              href="/fonts/BrandonGrotesqueWebRegular/font.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              href="/fonts/VenusianBoldExtended/font.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
          </Helmet>
          <GlobalStyles />
          <div className="viewport-wide">
            <Basket />
            <Header />
            <AnimatePresence initial exitBeforeEnter>
              <motion.main
                key={path}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {children}
              </motion.main>
            </AnimatePresence>
            <Footer alternate={useAlternateFooter} />
            <CookiePanel />
            <NewsletterSignUpModal />
            <AgeGate />
          </div>
        </ShopifyContextProvider>
      </PageContextProvider>
    </Provider>
  );
};

export default Layout;
