import { Cloudinary, Transformation } from 'cloudinary-core';

const cl = Cloudinary.new({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  cloud_name: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
  secure: true,
});

export default function imageUrl(
  publicId: string | undefined,
  rawTransformation: string | undefined,
): string {
  if (!publicId) {
    return '';
  }

  const transformation = Transformation.new().rawTransformation(rawTransformation);

  // Ensure we're specifying a format...
  if (containsFormatTransformation(rawTransformation)) {
    return cl.url(publicId, transformation);
  }

  // ...otherwise serve with "f_auto" for WebP support.
  return cl.url(publicId, transformation.chain().fetchFormat('auto').quality('auto'));
}

const FormatRegEx = /(\/|\,)(f_.*?)(\/|\,)/;

function containsFormatTransformation(transformation?: string): boolean {
  return !!transformation && FormatRegEx.test(transformation);
}
