import buttonStyles from '@presentation/Button/Button.styles';
import textBoxStyles from '@presentation/FormControls/Textbox/TextBox.styles';
import { markdownStyles } from '@utils/markdown';
import theme from '@utils/theme';
import styled from 'styled-components';

const Container = styled.section`
  ${buttonStyles.ButtonText} {
    font-size: 16px;
    line-height: 1em;
    padding: 9px 11px;
  }

  ${textBoxStyles.Input} {
    font-size: 18px;
    line-height: 1em;
    padding: 16px;
    padding-right: 200px;
  }
`;

const Title = styled.h2`
  color: ${theme.brand.red.default};
  ${theme.fonts.venusian};
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 24px;
  padding-right: 96px;
  text-transform: uppercase;
`;

const Content = styled.div`
  ${markdownStyles};
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.medium};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
`;

export default {
  Container,
  Content,
  Title,
};
