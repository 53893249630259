import CookiePanelPres from '@presentation/CookiePanel';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

export interface CookiePanelProps {}

const CookiePanel: React.FC<CookiePanelProps> = () => {
  const data = useCookiePanelQuery();
  return <CookiePanelPres content={data.master.cookiePanelContent?.cookiePanelContent} />;
};

export default CookiePanel;

interface CookiePanelQuery {
  master: ContentModels.ContentfulMaster;
}

function useCookiePanelQuery(): CookiePanelQuery {
  return useStaticQuery<CookiePanelQuery>(graphql`
    query CookiePanelQuery {
      master: contentfulMaster {
        cookiePanelContent {
          cookiePanelContent
        }
      }
    }
  `);
}
