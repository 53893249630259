import HeaderPres, { headerLogo } from '@presentation/Header';
import { image } from '@presentation/Navigation';
import { NavigationItemModel } from '@presentation/Navigation/NavigationItem';
import { useAppSelector } from '@redux/store';
import { isMenuItem } from '@utils/guards';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';
import { Contentful } from 'schema/Contentful';

const HeaderLogoPublicId = 'header-logo';

const Header: React.FC = () => {
  const data = useHeaderQuery();

  const { checkout, lastAdded } = useAppSelector((state) => state.shopify);

  const lastAddedLineItem = checkout?.lineItems.find(
    (item) => item.variant?.id === lastAdded?.variantId,
  );

  const cartCount =
    checkout?.lineItems.reduce((runningTotal, item) => item.quantity + runningTotal, 0) ?? 0;

  const defaultNavigationImageId = data.master.defaultNavigationImage?.[0]?.public_id;
  const defaultNavigationImage = image(defaultNavigationImageId);

  return (
    <HeaderPres
      cartCount={cartCount}
      defaultNavigationImage={defaultNavigationImage}
      itemsPrimary={createItemArray(data.master.primaryNavigation)}
      itemsSecondary={createItemArray(data.master.secondaryNavigation)}
      logo={headerLogo(HeaderLogoPublicId)}
      notification={{
        content1: data.master.notificationContent1?.notificationContent1,
        content2: data.master.notificationContent2?.notificationContent2,
      }}
      basketNotification={{
        id: lastAdded?.id,
        title: lastAddedLineItem?.title,
        quantity: lastAdded?.quantity,
      }}
    />
  );
};

export default Header;

function createItemArray(entries?: Contentful.ContentfulEntry[]): NavigationItemModel[] {
  const menuItems = entries?.filter(isMenuItem) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulMenuItem): NavigationItemModel {
  return {
    id: entry.id ?? '',
    image: image(entry.image?.[0]?.public_id),
    title: entry.title ?? '',
    url: entry.linkExternal ?? entry.linkInternal?.url,
  };
}

interface HeaderQuery {
  master: ContentModels.ContentfulMaster;
}

function useHeaderQuery(): HeaderQuery {
  return useStaticQuery<HeaderQuery>(graphql`
    query HeaderQuery {
      master: contentfulMaster {
        defaultNavigationImage
        notificationContent1 {
          notificationContent1
        }
        notificationContent2 {
          notificationContent2
        }
        primaryNavigation {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          ... on ContentfulMenuItem {
            image
            linkExternal
            linkInternal {
              id
              ... on NodeWithUrl {
                url
              }
            }
            title
          }
        }
        secondaryNavigation {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          ... on ContentfulMenuItem {
            image
            linkExternal
            linkInternal {
              id
              ... on NodeWithUrl {
                url
              }
            }
            title
          }
        }
      }
    }
  `);
}
