/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@presentation/Button';
import CheckboxSingle from '@presentation/FormControls/CheckboxSingle';
import TextBox from '@presentation/FormControls/Textbox';
import { EMAIL_REGEX } from '@utils/validation';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useBoolean } from 'react-use';
import s from './NewsletterSignUpForm.styles';

export interface NewsletterSignUpFormProps {
  checkboxLabel: string;
  content?: string;
  idPrefix: string;
  onSuccess?: () => void;
}

const FormName = 'NewsletterSignUp';

export type FormFields = {
  emailAddress: string;
  marketing: boolean;
};

const NewsletterSignUpForm: React.FC<NewsletterSignUpFormProps> = ({
  checkboxLabel,
  content,
  idPrefix,
  onSuccess,
}) => {
  const { errors, register, handleSubmit } = useForm<FormFields>();

  const [isSubmitting, setIsSubmitting] = useBoolean(false);

  // POST to Netlify forms.
  // @see: https://docs.netlify.com/forms/setup/#submit-javascript-rendered-forms-with-ajax
  const onSubmit = async (
    data: Record<string, any>,
    event?: React.BaseSyntheticEvent,
  ): Promise<void> => {
    setIsSubmitting(true);

    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        ...data,
        'form-name': event?.target.getAttribute('name'),
        honeypot: 'bot-field',
      }),
    });

    if (onSuccess) {
      onSuccess();
    }

    setIsSubmitting(false);
  };

  return (
    <s.Form
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      name={FormName}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input name="form-name" type="hidden" value={FormName} />
      <s.FormField>
        <s.FormLabel htmlFor="emailAddress">Email address</s.FormLabel>
        <TextBox
          ref={register({ pattern: EMAIL_REGEX, required: true })}
          id={`${idPrefix}-emailAddress`}
          hasError={!!errors.emailAddress}
          name="emailAddress"
          placeholder="Enter email address"
          type="email"
        />
        <Button disabled={isSubmitting} type="submit">
          Submit
        </Button>
      </s.FormField>
      <CheckboxSingle
        ref={register({ required: true })}
        id={`${idPrefix}-marketing`}
        hasError={!!errors.marketing}
        name="marketing"
        label={checkboxLabel}
      />
      <s.Honeypot
        ref={register({ required: false })}
        autoComplete="off"
        name="bot-field"
        type="text"
      />
      {content && (
        <s.FormContent>
          <Markdown>{content}</Markdown>
        </s.FormContent>
      )}
    </s.Form>
  );
};

export default NewsletterSignUpForm;

// Encode data using URL-encoding.
// @see: https://docs.netlify.com/forms/setup/#submit-javascript-rendered-forms-with-ajax
function encode(data: Record<string, any>): string {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}
