import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import styled from 'styled-components';
import ageGateStyles from '@presentation/AgeGate/AgeGate.styles';
import contactFormStyles from '@presentation/ContactForm/ContactForm.styles';

const Link = styled.a`
  display: inline-block;
  color: ${theme.brand.blue.default};
  filter: brightness(1);
  outline: none;
  transition: filter 0.3s ease-out, transform 0.3s ease-out;

  > svg {
    height: 100%;
    width: 100%;
  }

  &:focus,
  &:hover {
    filter: brightness(1.35);
    transform: translate3d(0, -2px, 0);
  }

  ${ageGateStyles.SocialLinks} & {
    display: block;
    height: 44px;
    margin: 0 12px;
    width: 44px;
  }

  ${contactFormStyles.SocialLinks} & {
    display: block;
    height: 38px;
    margin: 0 8px;
    width: 38px;

    @media ${from(Device.TabletLarge)} {
      height: 44px;
      width: 44px;
    }
  }
`;

export default {
  Link,
};
