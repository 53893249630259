import { Contentful } from 'schema/Contentful';
import { ContentModels } from 'schema/ContentModels';

export function isCocktail(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulCocktail {
  return entry.sys?.contentType?.sys?.id === 'cocktail';
}

export function isFact(entry: Contentful.ContentfulEntry): entry is ContentModels.ContentfulFact {
  return entry.sys?.contentType?.sys?.id === 'fact';
}

export function isGalleryItem(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulGalleryItem {
  return entry.sys?.contentType?.sys?.id === 'galleryItem';
}

export function isHeroBannerItem(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulHeroBannerItem {
  return entry.sys?.contentType?.sys?.id === 'heroBannerItem';
}

export function isIngredient(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulIngredient {
  return entry.sys?.contentType?.sys?.id === 'ingredient';
}

export function isMenuItem(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulMenuItem {
  return entry.sys?.contentType?.sys?.id === 'menuItem';
}

export function isProduct(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulProduct {
  return entry.sys?.contentType?.sys?.id === 'product';
}

export function isReview(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulReview {
  return entry.sys?.contentType?.sys?.id === 'review';
}

export function isSocialLink(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulSocialLink {
  return entry.sys?.contentType?.sys?.id === 'socialLink';
}

export function isStockist(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulStockist {
  return entry.sys?.contentType?.sys?.id === 'stockist';
}

export function isTestimonialsItem(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulTestimonialsItem {
  return entry.sys?.contentType?.sys?.id === 'testimonialsItem';
}

export function isTickerItem(
  entry: Contentful.ContentfulEntry,
): entry is ContentModels.ContentfulTickerItem {
  return entry.sys?.contentType?.sys?.id === 'tickerItem';
}
