import { css, FlattenSimpleInterpolation } from 'styled-components';
import bgTiled from '@img/stressed-bg-tiled.jpg';

export default function plyrStyles(): FlattenSimpleInterpolation {
  const styles = css`
    .plyr__control--overlaid {
      background: transparent url(${bgTiled});
      background-repeat: repeat;
      transform: translate3d(-50%, -50%, 0);
      transition: filter 0.3s ease-out, transform 0.3s ease-out;
    }

    .plyr--video .plyr__control--overlaid.plyr__tab-focus,
    .plyr--video .plyr__control--overlaid:hover,
    .plyr--video .plyr__control--overlaid[aria-expanded='true'] {
      background: transparent url(${bgTiled});
      background-repeat: repeat;
      filter: brightness(1.35);
      transform: translate3d(-50%, -50%, 0) translate3d(0, -2px, 0);
    }
  `;

  return styles;
}
