import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Notification = styled(motion.div)`
  position: absolute;
  top: calc(100% + 24px);
  right: 0;
  display: flex;
  background: ${theme.brand.blue.light};
  border: 3px solid ${theme.brand.red.dark};
  border-radius: 5px;
  color: ${theme.brand.blue.dark};
  flex-direction: row;
  ${theme.fonts.venusian};
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 1.2em;
  max-width: 280px;
  padding: 16px;
  place-content: center;
  place-items: center;
  text-align: right;
  text-transform: uppercase;
  width: 100vw;

  @media ${from(Device.MobileLarge)} {
    max-width: 420px;
  }
`;

const Title = styled.span`
  display: block;
  margin-right: 12px;
`;

const Quantity = styled.span`
  display: block;
  color: ${theme.brand.red.dark};
`;

export default {
  Notification,
  Quantity,
  Title,
};
