import bgLinesTiled from '@img/lines-bg-tiled.jpg';
import theme from '@utils/theme';
import styled from 'styled-components';
import productCtaStyles from '@presentation/ProductCta/ProductCta.styles';
import { Device, from } from '@utils/media';

const Svg = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`;

const FrameOuterBorder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${theme.brand.blue.dark};
  border-radius: 5px;
  clip-path: url(#image-frame);
  height: 100%;
  padding: 0;
`;

const FrameInner = styled.div`
  display: block;
  background: transparent url(${bgLinesTiled});
  background-repeat: repeat;
  border-radius: 5px;
  clip-path: url(#image-frame);
  flex-direction: column;
  margin: 4px;
  height: 100%;
  padding: 0;
`;

const FrameInnerBorder = styled.div`
  display: block;
  background: ${theme.brand.blue.dark};
  border: 4px solid ${theme.brand.blue.dark};
  border-radius: 5px;
  flex-direction: column;
  margin: 7%;

  ${productCtaStyles.ProductWrapper} & {
    margin: 24px;

    @media ${from(Device.Tablet)} {
      margin: 32px 40px;
    }
  }
`;

const FrameContentWrapper = styled.div`
  display: block;
  min-width: 0;

  ${productCtaStyles.ProductWrapper} & {
    background: ${theme.brand.white.off};
    padding: 24px 16px;
  }
`;

export default {
  FrameContentWrapper,
  FrameInner,
  FrameInnerBorder,
  FrameOuterBorder,
  Svg,
};
