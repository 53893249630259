import { Variants } from 'framer-motion';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import s from './Navigation.styles';

export interface NavigationItemImageProps {
  active: boolean;
  image: FluidObject;
}

const NavigationItemImage: React.FC<NavigationItemImageProps> = ({ active, image }) => {
  const imageVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <s.Image animate={active ? 'visible' : 'hidden'} initial="hidden" variants={imageVariants}>
      <GatsbyImage fluid={image} />
    </s.Image>
  );
};

export default NavigationItemImage;
