import srOnly from '@utils/srOnly';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';
import IconChevronRight from '!babel-loader!react-svg-loader!@img/icon-chevron-right.svg';
import { Device, from } from '@utils/media';

export interface WrapperProps {
  direction: 'next' | 'prev';
  size: 'medium';
}

const Wrapper = styled.button<WrapperProps>`
  display: inline-flex;
  background: ${theme.brand.white.default};
  border: 0;
  border-radius: 50%;
  color: ${theme.brand.blue.dark};
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
  place-content: center;
  place-items: center;
  pointer-events: all;
  transition: background-color 0.3s ease-out, color 0.3s ease-out, filter 0.3s ease-out,
    opacity 0.3s ease-out, transform 0.3s ease-out;
  transform: translate3d(0, 0, 0.1);
  user-select: none;
  will-change: transform;

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.7;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    background: ${theme.brand.blue.dark};
    color: ${theme.brand.white.default};
  }

  ${({ direction }) => {
    switch (direction) {
      case 'next':
        return css`
          &:focus:not(:disabled),
          &:hover:not(:disabled) {
            transform: translate3d(4px, 0, 0);
          }

          ${Icon} {
            transform: rotate(0deg);
          }
        `;

      case 'prev':
        return css`
          &:focus:not(:disabled),
          &:hover:not(:disabled) {
            transform: translate3d(-4px, 0, 0);
          }

          ${Icon} {
            transform: rotate(180deg);
          }
        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'medium':
        return css`
          flex-basis: 32px;
          height: 32px;
          width: 32px;

          @media ${from(Device.TabletLarge)} {
            flex-basis: 80px;
            height: 80px;
            width: 80px;
          }
        `;
    }
  }}
`;

const Label = styled.span`
  ${srOnly}
`;

const Icon = styled(IconChevronRight)`
  display: block;
  height: 12px;
  width: 6px;

  path {
    stroke-width: 5px;
  }

  @media ${from(Device.TabletLarge)} {
    height: 25px;
    width: 13px;
  }
`;

export default {
  Icon,
  Label,
  Wrapper,
};
