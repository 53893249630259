import NewsletterSignUp, { NewsletterSignUpProps } from '@presentation/NewsletterSignUp';
import imageUrl from '@utils/cloudinary';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import { Custom } from 'schema/Custom';
import s from './Footer.styles';

export interface FooterLinkModel {
  id: string;
  title: string;
  url?: string;
}

export interface FooterLinkProps extends FooterLinkModel {
  isTitle?: boolean;
}

const FooterLink: React.FC<FooterLinkProps> = ({ isTitle = false, title, url }) => {
  return (
    <s.LinkItem>
      {url && (
        <s.Link isTitle={isTitle} to={url}>
          {title}
        </s.Link>
      )}
      {!url && (
        <s.Link isTitle={isTitle} as="span">
          {title}
        </s.Link>
      )}
    </s.LinkItem>
  );
};

export interface FooterLinksProps {
  items: FooterLinkModel[];
  layout?: 'vertical' | 'horizontal';
  weight?: 'regular' | 'bold';
}

const FooterLinks: React.FC<FooterLinksProps> = ({
  items,
  layout = 'vertical',
  weight = 'regular',
}) => {
  return (
    <s.LinkList layout={layout} weight={weight}>
      {items.map((item, index) => (
        <FooterLink key={item.id} {...item} isTitle={layout === 'vertical' && index === 0} />
      ))}
    </s.LinkList>
  );
};

export interface FooterProps {
  alternate: boolean;
  newsletter: NewsletterSignUpProps;
  backgroundImage: FluidObject;
  items1: FooterLinkModel[];
  items2: FooterLinkModel[];
  items3: FooterLinkModel[];
  legalItems: FooterLinkModel[];
}

const Footer: React.FC<FooterProps> = ({
  alternate,
  backgroundImage,
  items1,
  items2,
  items3,
  legalItems,
  newsletter,
}) => {
  return (
    <>
      <s.ClipPath>
        <clipPath id="footer-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0,1 V0.163 C0.01,0.179,0.019,0.205,0.019,0.243 C0.019,0.194,0.062,0.271,0.062,0.283 C0.062,0.286,0.102,0.333,0.102,0.379 C0.102,0.342,0.114,0.367,0.125,0.393 C0.135,0.416,0.145,0.439,0.145,0.415 C0.145,0.371,0.189,0.363,0.189,0.441 C0.189,0.429,0.234,0.435,0.234,0.386 C0.234,0.409,0.247,0.407,0.259,0.405 C0.269,0.403,0.277,0.401,0.277,0.412 C0.277,0.463,0.32,0.443,0.32,0.502 C0.32,0.519,0.363,0.528,0.363,0.537 C0.363,0.591,0.375,0.545,0.386,0.499 C0.397,0.456,0.408,0.413,0.408,0.454 C0.408,0.417,0.451,0.518,0.451,0.541 C0.451,0.558,0.46,0.518,0.469,0.478 C0.481,0.427,0.495,0.375,0.495,0.443 C0.495,0.515,0.538,0.425,0.538,0.403 C0.538,0.313,0.592,0.486,0.592,0.512 C0.592,0.541,0.602,0.484,0.614,0.427 C0.625,0.365,0.638,0.304,0.638,0.348 C0.638,0.473,0.685,0.318,0.685,0.295 C0.685,0.275,0.727,0.179,0.727,0.164 C0.727,0.225,0.772,0.115,0.772,0.111 C0.772,0.101,0.821,-0.004,0.821,0.179 C0.821,0.164,0.864,-0.002,0.864,0 C0.864,0.031,0.911,0.141,0.911,0.107 C0.911,0.121,0.956,0.133,0.956,0.14 C0.956,0.159,0.988,0.147,1,0.138 V1 H0" />
        </clipPath>
      </s.ClipPath>
      <s.Wrapper alternate={alternate}>
        {backgroundImage && (
          <s.BackgroundImage>
            <GatsbyImage
              fluid={backgroundImage}
              imgStyle={{ objectFit: 'cover', objectPosition: 'center bottom' }}
            />
          </s.BackgroundImage>
        )}
        <s.NewsletterWrapper>
          <s.SiteWide>
            <NewsletterSignUp {...newsletter} />
          </s.SiteWide>
        </s.NewsletterWrapper>
        <s.ContentWrapper>
          <s.SiteWide>
            <s.LinkListWrapper>
              {items1?.length && <FooterLinks weight="bold" items={items1} />}
              {items2?.length && <FooterLinks weight="bold" items={items2} />}
              {items3?.length && <FooterLinks weight="bold" items={items3} />}
            </s.LinkListWrapper>
            <s.FooterBottom>
              {legalItems?.length && <FooterLinks layout="horizontal" items={legalItems} />}
              <s.LegalText>&copy; Copyright {new Date().getFullYear()} Hawksbill Rum</s.LegalText>
            </s.FooterBottom>
          </s.SiteWide>
        </s.ContentWrapper>
      </s.Wrapper>
    </>
  );
};

export default Footer;

export function image(asset: Custom.CloudinaryAsset | undefined): FluidObject {
  return {
    aspectRatio: (asset?.width ?? 1) / (asset?.height ?? 1),
    sizes: '(max-width: 1920px) 100vw, 1920px',
    src: imageUrl(asset?.public_id, 'c_fill,g_auto,w_1920'),
    srcSet: `
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_480')} 480w,
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_960')} 960w,
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_1440')} 1440w,
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_1920')} 1920w
    `,
  };
}

export function imageAlt(asset: Custom.CloudinaryAsset | undefined): FluidObject {
  return {
    aspectRatio: (asset?.width ?? 1) / (asset?.height ?? 1),
    sizes: '(max-width: 1920px) 100vw, 1920px',
    src: imageUrl(asset?.public_id, 'c_fill,g_auto,w_1920'),
    srcSet: `
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_480')} 480w,
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_960')} 960w,
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_1440')} 1440w,
      ${imageUrl(asset?.public_id, 'c_fill,g_auto,w_1920')} 1920w
    `,
  };
}
