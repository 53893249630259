import Modal from '@presentation/Modal';
import Cookie from 'js-cookie';
import React, { useEffect } from 'react';
import ScrollLock from 'react-scrolllock';
import { useBoolean } from 'react-use';

export interface ExitIntentProps {
  cookieName?: string;
}

const ExitIntent: React.FC<ExitIntentProps> = ({ children, cookieName }) => {
  const [isActive, toggleActive] = useBoolean(false);
  const [wasActive, toggleWasActive] = useBoolean(false);

  usePageLeave(() => {
    const cookieValue = cookieName ? Cookie.get(cookieName) : undefined;

    if (!cookieValue && !wasActive) {
      toggleActive(true);
    }
  }, [wasActive]);

  return (
    <ScrollLock isActive={isActive}>
      <Modal
        active={isActive}
        onClose={() => {
          toggleActive(false);
          toggleWasActive(true);
        }}
      >
        {children}
      </Modal>
    </ScrollLock>
  );
};

export default ExitIntent;

function usePageLeave(onPageLeave: () => void, args: React.DependencyList = []): void {
  useEffect(() => {
    if (!onPageLeave) {
      return;
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const handler = (event: MouseEvent): void => {
      event = event ? event : (window.event as any);
      const from = event.relatedTarget;
      if (!from || (from as any).nodeName === 'HTML') {
        onPageLeave();
      }
    };
    /* eslint-enable @typescript-eslint/no-explicit-any */

    document.addEventListener('mouseleave', handler);

    return () => {
      document.removeEventListener('mouseleave', handler);
    };
  }, args);
}
