import Markdown from 'markdown-to-jsx';
import React from 'react';
import s from './NotificationPanel.styles';

export interface NotificationPanelProps {
  content1?: string;
  content2?: string;
}

const NotificationPanel: React.FC<NotificationPanelProps> = ({ content1, content2 }) => {
  if (!content1 && !content2) {
    return null;
  }

  return (
    <s.Wrapper>
      <s.SiteWide>
        {content1 && (
          <s.DesktopOnlyText>
            <Markdown>{content1}</Markdown>
          </s.DesktopOnlyText>
        )}
        {content2 && (
          <>
            {content1 && <s.Separator>&mdash;</s.Separator>}
            <s.Text>
              <Markdown>{content2}</Markdown>
            </s.Text>
          </>
        )}
      </s.SiteWide>
    </s.Wrapper>
  );
};

export default NotificationPanel;
