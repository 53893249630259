import { css } from 'styled-components';
import theme from './theme';

const scrollbar = css`
  &::-webkit-scrollbar-track {
    background-color: ${theme.brand.paper.default};
  }

  &::-webkit-scrollbar {
    background-color: ${theme.brand.paper.default};
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.brand.red.default};
    border-radius: 3px;
    cursor: pointer;
  }
`;

export default scrollbar;
