import classNames from 'classnames';
import React, { ReactNode } from 'react';
import s from './SectionWrapper.styles';

export enum ComponentThemeTaxonomy {
  NONE = 'none',
  LIGHT_BLUE = 'light_blue',
  WHITE = 'white',
}

export enum SectionFlush {
  NONE = '',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface SectionWrapperProps {
  children: ReactNode;
  className?: string;
  fade?: boolean;
  flush?: SectionFlush;
  fullWidth?: boolean;
  id?: string;
  margin?: boolean;
  overflow?: boolean;
  padding?: boolean;
  style?: React.CSSProperties;
  theme?: ComponentThemeTaxonomy;
}

const SectionWrapper = React.forwardRef<HTMLDivElement, SectionWrapperProps>(
  (
    {
      children,
      id,
      className: customClassName,
      fade = true,
      flush = SectionFlush.NONE,
      fullWidth = false,
      margin = false,
      overflow = false,
      padding = false,
      theme = ComponentThemeTaxonomy.NONE,
    },
    ref,
  ) => {
    const className = classNames({
      [customClassName ?? '']: !!customClassName,
      [`theme-${theme}`]: !!theme,
      [`no-fade`]: !fade,
    });

    return (
      <s.Wrapper
        ref={ref}
        id={id}
        className={className}
        flush={flush}
        margin={margin}
        overflow={overflow}
        padding={padding}
        theme={theme}
      >
        <s.Inner>
          {fullWidth && children}
          {!fullWidth && <s.SiteWide>{children}</s.SiteWide>}
        </s.Inner>
      </s.Wrapper>
    );
  },
);

SectionWrapper.displayName = 'SectionWrapper';

export default SectionWrapper;
