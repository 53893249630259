import bgPaperTiled from '@img/paper-bg-tiled.jpg';
import SvgSugarcane from '!babel-loader!react-svg-loader!@img/sugarcane-01.svg';
import SvgTurtle from '!babel-loader!react-svg-loader!@img/turtle.svg';
import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { Device, from } from '@utils/media';
import scrollbar from '@utils/scrollbar';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';

const Wrapper = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  background: ${theme.brand.paper.default} url(${bgPaperTiled});
  background-repeat: repeat;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  z-index: 15;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const StyledSiteWide = styled(SiteWide)`
  display: grid;
  height: 100%;
`;

const Inner = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  min-height: 0;

  @media ${from(Device.Desktop)} {
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
`;

const ImageColumn = styled.div`
  display: none;
  min-height: 0;
  padding: 48px 0;

  @media ${from(Device.Desktop)} {
    display: block;
  }
`;

const LinkColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 96px 0 32px;
  min-height: 0;

  @media ${from(Device.Desktop)} {
    padding: 60px 0;
  }
`;

const LinkColumnScroll = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 8px;
  width: 100%;

  ${scrollbar};
`;

const ImageList = styled.div`
  display: grid;
`;

const Image = styled(motion.div)`
  grid-column: 1;
  grid-row: 1;
  transition: opacity 0.5s ease-out;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;

const ItemList = styled(motion.div)<{ level: 'primary' | 'secondary' }>`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  place-content: center;
  place-items: center;
  text-align: center;
  width: 100%;

  ${({ level }) =>
    level === 'secondary' &&
    css`
      ${Link} {
        font-size: 20px;
        line-height: 1.2em;
        padding: 8px 0;

        @media ${from(Device.Tablet)} {
          font-size: 25px;
        }
      }
    `}
`;

const Item = styled(motion.div)`
  display: block;
  opacity: 0;
  width: 100%;
`;

const Link = styled(GatsbyLink)<{ dim: boolean }>`
  display: block;
  color: ${theme.brand.blue.default};
  ${theme.fonts.venusian};
  font-size: 25px;
  line-height: 1.2em;
  padding: 16px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.5s ease-out;

  @media ${from(Device.DesktopLarge)} {
    font-size: 50px;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    color: ${theme.brand.blue.dark};
    text-decoration: underline;
  }

  ${({ dim }) =>
    dim &&
    css`
      color: ${theme.brand.paper.dark};
    `}
`;

const ItemListSeparator = styled(motion.div)``;

const Turtle = styled(SvgTurtle)`
  display: block;
  flex: 0 0 44px;
  height: 44px;
  margin: 48px 0;
  opacity: 0.5;
  width: 44px;
`;

const Sugarcane = styled(SvgSugarcane)`
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  color: #1d1d1b;
  opacity: 0.1;
  pointer-events: none;
  transform: translate3d(50%, 0, 0) translate3d(280px, 240px, 0) rotate(-50deg) scale(0.8);
  transform-origin: center bottom;
  z-index: 1;
`;

export default {
  Image,
  ImageColumn,
  ImageList,
  Inner,
  Item,
  ItemList,
  ItemListSeparator,
  Link,
  LinkColumn,
  LinkColumnScroll,
  SiteWide: StyledSiteWide,
  Sugarcane,
  Turtle,
  Wrapper,
};
