import { Button } from '@presentation/Button';
import Cookie from 'js-cookie';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import s from './CookiePanel.styles';

export interface CookiePanelProps {
  content?: string;
}

const COOKIE_NAME = 'accept-cookies';

const CookiePanel: React.FC<CookiePanelProps> = ({ content }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const value = Cookie.get(COOKIE_NAME);
    setIsVisible(!value);
  }, []);

  if (!content) {
    return null;
  }

  return (
    <s.Wrapper visible={isVisible}>
      <s.SiteWide>
        <s.Text>
          <Markdown>{content}</Markdown>
        </s.Text>
        <Button
          small
          onClick={(event): void => {
            event.preventDefault();
            Cookie.set(COOKIE_NAME, '1', { expires: 30 });
            setIsVisible(false);
          }}
        >
          Accept
        </Button>
      </s.SiteWide>
    </s.Wrapper>
  );
};

export default CookiePanel;
