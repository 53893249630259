import { Button } from '@presentation/Button';
import SocialIcon, { SocialLinkProps } from '@presentation/SocialIcon';
import GatsbyImage, { FixedObject } from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import React, { useEffect } from 'react';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { useBoolean, useCookie } from 'react-use';
import s from './AgeGate.styles';

export interface AgeGateProps {
  badge?: FixedObject;
  content: string;
  ctaText: string;
  socialLinks: SocialLinkProps[];
  title: string;
}

const AgeGate: React.FC<AgeGateProps> = ({ badge, content, ctaText, socialLinks, title }) => {
  const [cookieValue, setCookieValue] = useCookie('age-gate');
  const [active, toggleActive] = useBoolean(false);

  useEffect(() => {
    // If we think this is a Google request then don't show age gate.
    const isGoogle = navigator.userAgent.toLowerCase().includes('googlebot');

    toggleActive(!isGoogle && !cookieValue);
  }, [cookieValue]);

  return (
    <s.Container active={active}>
      <ScrollLock isActive={active} />
      <s.SvgSugarcane1 />
      <s.SvgSugarcane2 />

      <TouchScrollable>
        <s.ContentWrapper>
          <s.ContentWrapperInner>
            {badge && (
              <s.Badge>
                <GatsbyImage fixed={badge} />
              </s.Badge>
            )}
            <s.Title>{title}</s.Title>
            <s.Turtle />
            <s.CallToAction>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  toggleActive(false);
                  setCookieValue('1', { expires: 30 });
                }}
              >
                {ctaText}
              </Button>
            </s.CallToAction>
            <s.Content>
              <Markdown>{content}</Markdown>
            </s.Content>
            <s.SocialLinks>
              {socialLinks.map((s) => (
                <SocialIcon key={s.id} {...s} />
              ))}
            </s.SocialLinks>
          </s.ContentWrapperInner>
        </s.ContentWrapper>
      </TouchScrollable>
    </s.Container>
  );
};

export default AgeGate;
