import initialiseCart from '@redux/reducers/shopify/thunks/fetchCart';
import { useAppDispatch } from '@redux/store';
import React, { useEffect } from 'react';

const ShopifyContextProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initialiseCart());
  }, []);

  return <>{children}</>;
};

export { ShopifyContextProvider };
