import { Variants } from 'framer-motion';
import { FluidObject } from 'gatsby-image';
import React from 'react';
import s from './Navigation.styles';

export interface NavigationItemModel {
  id: string;
  title: string;
  url?: string;
  image?: FluidObject;
}

export interface NavigationItemProps extends NavigationItemModel {
  active: boolean;
  dim: boolean;
  onEnter: () => void;
  onExit: () => void;
}

const NavigationItem = React.forwardRef<HTMLDivElement, NavigationItemProps>(
  ({ dim, title, url, onEnter, onExit }, ref) => {
    const itemVariants: Variants = {
      hidden: {
        opacity: 0,
        scale: 0.8,
      },
      visible: {
        opacity: 1,
        scale: 1,
        transformOrigin: 'center bottom',
      },
    };

    return (
      <s.Item ref={ref} initial="hidden" variants={itemVariants}>
        <s.Link
          dim={dim}
          to={url ?? '#'}
          onMouseEnter={onEnter}
          onMouseLeave={onExit}
          onFocus={onEnter}
          onBlur={onExit}
        >
          {title}
        </s.Link>
      </s.Item>
    );
  },
);

NavigationItem.displayName = 'NavigationItem';

export default NavigationItem;
