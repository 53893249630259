import theme from '@utils/theme';
import styled from 'styled-components';

const Svg = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`;

const FrameOuterBorder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${theme.brand.blue.dark};
  border-radius: 5px;
  clip-path: url(#content-frame);
  height: 100%;
  max-width: 720px;
  padding: 0;
`;

const FrameInner = styled.div`
  display: flex;
  background: ${theme.brand.paper.default};
  border-radius: 5px;
  clip-path: url(#content-frame);
  flex-direction: column;
  margin: 4px;
  height: 100%;
  padding: 0;
  place-content: center;
`;

const FrameInnerBorder = styled.div`
  display: flex;
  background: ${theme.brand.blue.dark};
  border: 4px solid ${theme.brand.blue.dark};
  border-radius: 5px;
  clip-path: url(#content-frame);
  flex-direction: column;
  margin: 4px;
`;

const FrameContentWrapper = styled.div`
  position: relative;
  display: block;
  background: ${theme.brand.paper.default};
  clip-path: url(#content-frame);
  padding: 48px;
`;

export default {
  FrameContentWrapper,
  FrameInner,
  FrameInnerBorder,
  FrameOuterBorder,
  Svg,
};
