import React from 'react';
import BasketPres, { BasketItemModel } from '@presentation/Basket';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { toggleBasket } from '@redux/reducers/shopify';
import removeFromCart from '@redux/reducers/shopify/thunks/removeFromCart';
import updateInCart from '@redux/reducers/shopify/thunks/updateInCart';
import { ContentModels } from 'schema/ContentModels';
import { graphql, useStaticQuery } from 'gatsby';
import { ShopifyBuy } from 'shopify';

export interface BasketProps {}

const Basket: React.FC<BasketProps> = () => {
  const dispatch = useAppDispatch();
  const { checkout, loading, showBasket } = useAppSelector((state) => state.shopify);
  const data = useBasketQuery();

  return (
    <BasketPres
      active={showBasket}
      content={data.master.basketCheckoutText ?? ''}
      items={createItemsArray(checkout?.lineItems ?? [])}
      subtotal={parseFloat(checkout?.subtotalPriceV2.amount ?? '')}
      onCheckout={() => {
        if (loading === 'idle' && checkout?.webUrl) {
          if (window.ga) {
            window.ga(function (tracker: any) {
              const linkerParam = tracker.get('linkerParam');
              window.location.href = `${checkout?.webUrl}&${linkerParam}`;
            });
          } else {
            window.location.href = checkout?.webUrl;
          }
        }
      }}
      onClose={() => dispatch(toggleBasket(false))}
      onItemRemove={(lineItemId) => {
        if (loading === 'idle') {
          dispatch(removeFromCart({ lineItemId }));
        }
      }}
      onItemUpdate={(lineItemId, quantity) => {
        if (loading === 'idle') {
          dispatch(updateInCart({ lineItemId, quantity }));
        }
      }}
    />
  );
};

export default Basket;

function createItemsArray(items: ShopifyBuy.LineItems[]): BasketItemModel[] {
  return items.map(createItemModel);
}

function createItemModel(item: ShopifyBuy.LineItems): BasketItemModel {
  return {
    id: item.id.toString(),
    itemTotal: parseFloat(item.variant?.price ?? 0) * item.quantity,
    quantity: item.quantity,
    thumbnail: getSmallImage(item.variant?.image?.src),
    title: item.title,
    variantTitle: item.variant?.title,
  };
}

interface BasketQuery {
  master: ContentModels.ContentfulMaster;
}

function useBasketQuery(): BasketQuery {
  const data = useStaticQuery<BasketQuery>(graphql`
    {
      master: contentfulMaster {
        basketCheckoutText
      }
    }
  `);

  return data;
}

/** Transforms the Shopify Image URL to the "_small" version which is 100x100.  */
function getSmallImage(url: string): string | undefined {
  if (!url) {
    return undefined;
  }

  const extIndex = url.lastIndexOf('.');
  const output = `${url.substring(0, extIndex)}_small${url.substring(extIndex)}`;
  return output;
}
