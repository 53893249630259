import React, { ReactNode } from 'react';
import s from './ImageFrame.styles';

export interface ImageFrameProps {
  clipPath?: ReactNode;
  clipPathId?: string;
}

const ImageFrame: React.FC<ImageFrameProps> = ({ children, clipPath, clipPathId }) => {
  return (
    <>
      <s.Svg>
        {clipPath && clipPathId ? (
          clipPath
        ) : (
          <clipPath id="image-frame" clipPathUnits="objectBoundingBox">
            <path d="M0.003,1 v-0.469 a0.036,0.03,0,0,0,0,-0.061 V0.002 h1 V0.471 a0.036,0.03,0,0,0,0,0.06 V1"></path>
          </clipPath>
        )}
      </s.Svg>
      <s.FrameOuterBorder style={{ clipPath: clipPathId ? `url(#${clipPathId})` : undefined }}>
        <s.FrameInner style={{ clipPath: clipPathId ? `url(#${clipPathId})` : undefined }}>
          <s.FrameInnerBorder>
            <s.FrameContentWrapper>{children}</s.FrameContentWrapper>
          </s.FrameInnerBorder>
        </s.FrameInner>
      </s.FrameOuterBorder>
    </>
  );
};

export default ImageFrame;
