import imageUrl from '@utils/cloudinary';
import { FluidObject } from 'gatsby-image';
import React, { useState } from 'react';
import NavigationItem, { NavigationItemModel } from './NavigationItem';
import NavigationItemImage from './NavigationItemImage';
import s from './Navigation.styles';
import { Variants } from 'framer-motion';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

export interface NavigationProps {
  active: boolean;
  defaultImage: FluidObject;
  itemsPrimary: NavigationItemModel[];
  itemsSecondary: NavigationItemModel[];
}

const Navigation: React.FC<NavigationProps> = ({
  active,
  defaultImage,
  itemsPrimary,
  itemsSecondary,
}) => {
  const [activeId, setActiveId] = useState<string | null>(null);

  const wrapperVariants: Variants = {
    hidden: {
      opacity: 0,
      pointerEvents: 'none',
      transitionEnd: {
        visibility: 'hidden',
      },
    },
    visible: {
      opacity: 1,
      visibility: 'visible',
      transitionEnd: {
        pointerEvents: 'all',
      },
    },
  };

  const itemListVariants = (delay: number): Variants => ({
    hidden: {},
    visible: {
      transition: {
        delayChildren: delay,
        staggerChildren: 0.15,
      },
    },
  });

  const separatorVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: itemsPrimary.length * 0.15,
      },
    },
  };

  return (
    <s.Wrapper initial="hidden" animate={active ? 'visible' : 'hidden'} variants={wrapperVariants}>
      <ScrollLock isActive={active} />
      <s.Sugarcane />
      <s.SiteWide>
        <s.Inner>
          <s.ImageColumn>
            <s.ImageList>
              <NavigationItemImage image={defaultImage} active={!activeId} />
              {itemsPrimary.map((item) =>
                item.image ? (
                  <NavigationItemImage
                    key={item.id}
                    active={activeId === item.id}
                    image={item.image}
                  />
                ) : null,
              )}
              {itemsSecondary.map((item) =>
                item.image ? (
                  <NavigationItemImage
                    key={item.id}
                    active={activeId === item.id}
                    image={item.image}
                  />
                ) : null,
              )}
            </s.ImageList>
          </s.ImageColumn>
          <s.LinkColumn>
            <TouchScrollable>
              <s.LinkColumnScroll>
                <s.ItemList level="primary" variants={itemListVariants(0)}>
                  {itemsPrimary.map((item) => (
                    <NavigationItem
                      key={item.id}
                      {...item}
                      active={activeId === item.id}
                      dim={!!activeId && activeId !== item.id}
                      onEnter={() => {
                        if (item.image) {
                          setActiveId(item.id);
                        }
                      }}
                      onExit={() => setActiveId(null)}
                    />
                  ))}
                </s.ItemList>
                <s.ItemListSeparator initial="hidden" variants={separatorVariants}>
                  <s.Turtle />
                </s.ItemListSeparator>
                <s.ItemList
                  level="secondary"
                  variants={itemListVariants((itemsPrimary.length + 1) * 0.15)}
                >
                  {itemsSecondary.map((item) => (
                    <NavigationItem
                      key={item.id}
                      {...item}
                      active={activeId === item.id}
                      dim={!!activeId && activeId !== item.id}
                      onEnter={() => {
                        if (item.image) {
                          setActiveId(item.id);
                        }
                      }}
                      onExit={() => setActiveId(null)}
                    />
                  ))}
                </s.ItemList>
              </s.LinkColumnScroll>
            </TouchScrollable>
          </s.LinkColumn>
        </s.Inner>
      </s.SiteWide>
    </s.Wrapper>
  );
};

export default Navigation;

export function image(publicId: string | undefined): FluidObject {
  return {
    aspectRatio: 1,
    sizes: '(max-width: 1600px) 50vw, 800px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_400,h_400'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_400,h_400')} 400w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_800,h_800')} 800w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1200,h_1200')} 1200w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_1600,h_1600')} 1600w
    `,
  };
}
