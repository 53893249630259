import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import bgTiled from '@img/stressed-bg-tiled.jpg';
import theme from '@utils/theme';
import heroBannerStyles from '@presentation/HeroBanner/HeroBanner.styles';
import { Device, from } from '@utils/media';

export interface ButtonProps {
  small: boolean;
}

const Button = styled(GatsbyLink)<ButtonProps>`
  display: inline-block;
  background: #2067ad;
  border: 0;
  clip-path: url(#button-clip-path);
  color: ${theme.brand.white.default};
  cursor: pointer;
  filter: brightness(1);
  ${theme.fonts.venusian};
  font-size: 13px;
  letter-spacing: 0.02em;
  line-height: 16px;
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  transform: translate3d(0, 0, 0);
  transition: filter 0.3s ease-out, transform 0.3s ease-out;
  will-change: transform;

  @media ${from(Device.DesktopSmall)} {
    font-size: 20px;
    line-height: 24px;
  }

  &:focus,
  &:hover {
    filter: brightness(1.35);
    transform: translate3d(0, -2px, 0);
  }

  ${heroBannerStyles.ItemCTAs} & {
    margin: 0 12px 24px;

    @media ${from(Device.DesktopSmall)} {
      margin: 0 12px;
    }
  }

  ${({ small }) =>
    small &&
    css`
      font-size: 13px;
      letter-spacing: 0.02em;
      line-height: 16px;

      ${ButtonText} {
        padding: 8px 12px;
      }

      @media ${from(Device.DesktopSmall)} {
        font-size: 13px;
        letter-spacing: 0.02em;
        line-height: 16px;

        ${ButtonText} {
          padding: 8px 12px;
        }
      }
    `}
`;

const ButtonOuter = styled.span`
  display: flex;
  background: ${theme.brand.white.default};
  clip-path: url(#button-clip-path);
  margin: 2px;
`;

const ButtonInner = styled.span`
  display: flex;
  background: transparent url(${bgTiled});
  background-repeat: repeat;
  clip-path: url(#button-clip-path);
  margin: 2px;
  width: 100%;
`;

const ButtonText = styled.span`
  display: block;
  padding: 12px 8px;
  width: 100%;

  @media ${from(Device.DesktopSmall)} {
    padding: 18px 22px;
  }
`;

const ClipPath = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
`;

export default {
  Button,
  ButtonInner,
  ButtonOuter,
  ButtonText,
  ClipPath,
};
