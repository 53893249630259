import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';

export interface WrapperProps {
  visible: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: block;
  background: ${theme.brand.blue.dark};
  color: ${theme.brand.white.default};
  transform: translate3d(0, 100%, 0);
  transition: transform 0.3s ease-out;
  z-index: 1000;

  ${({ visible }) =>
    visible &&
    css`
      transform: translate3d(0, 0, 0);
    `}
`;

const StyledSiteWide = styled(SiteWide)`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;

  @media ${from(Device.Tablet)} {
    flex-direction: row;
    text-align: left;
  }
`;

const Text = styled.div`
  ${markdownStyles};
  display: block;
  ${theme.fonts.brandon.medium};
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 16px;

  a {
    color: ${theme.brand.white.default};
    text-decoration: underline;
  }

  @media ${from(Device.Tablet)} {
    margin-bottom: 0;
    margin-right: 32px;
  }
`;

export default {
  SiteWide: StyledSiteWide,
  Text,
  Wrapper,
};
