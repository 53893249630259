import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import styled from 'styled-components';
import buttonStyles from '@presentation/Button/Button.styles';
import textBoxStyles from '@presentation/FormControls/Textbox/TextBox.styles';
import formStyles from '@presentation/NewsletterSignUpForm/NewsletterSignUpForm.styles';
import srOnly from '@utils/srOnly';
import { markdownStyles } from '@utils/markdown';

const Wrapper = styled.div`
  ${formStyles.FormContent} {
    color: ${theme.brand.white.default};

    a {
      color: ${theme.brand.white.default};
    }
  }
`;

const StyledSiteWide = styled(SiteWide)``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  place-content: center;
  place-items: center;
  margin: 0 auto 32px;
  max-width: 700px;
  text-align: center;
`;

const Title = styled.h2`
  color: ${theme.brand.paper.default};
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 60px;
    line-height: 70px;
  }
`;

const Subtitle = styled.h3`
  color: ${theme.brand.paper.default};
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 24px;
  }
`;

const Content = styled.div`
  ${markdownStyles};
  color: ${theme.brand.paper.default};
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
  text-align: center;

  @media ${from(Device.Tablet)} {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  margin: 0 auto 32px;
  max-width: 850px;
`;

const FormField = styled.div`
  display: grid;
  margin-bottom: 16px;
  width: 100%;

  ${textBoxStyles.Input} {
    grid-column: 1;
    grid-row: 1;
    padding-right: 112px;

    @media ${from(Device.Tablet)} {
      padding-right: 200px;
    }
  }

  ${buttonStyles.Button} {
    grid-column: 1;
    grid-row: 1;
    margin: auto 16px auto auto;

    @media ${from(Device.Tablet)} {
      margin: auto 32px auto auto;
    }
  }
`;

const FormLabel = styled.label`
  ${srOnly}
`;

export default {
  Content,
  Form,
  FormField,
  FormLabel,
  SiteWide: StyledSiteWide,
  Subtitle,
  Title,
  TitleContainer,
  Wrapper,
};
