import AgeGatePres from '@presentation/AgeGate';
import { SocialLinkProps } from '@presentation/SocialIcon';
import { isSocialLink } from '@utils/guards';
import { graphql, useStaticQuery } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import { Contentful } from 'schema/Contentful';
import { ContentModels } from 'schema/ContentModels';

export interface AgeGateProps {}

const AgeGate: React.FC<AgeGateProps> = () => {
  const data = useAgeGateQuery();
  return (
    <AgeGatePres
      badge={data.badge?.childCloudinaryAsset?.fixed}
      content={data.ageGate.content?.content ?? ''}
      ctaText={data.ageGate.ctaText ?? ''}
      socialLinks={createItemArray(data.ageGate.socialLinks)}
      title={data.ageGate.title ?? ''}
    />
  );
};

export default AgeGate;

function createItemArray(entries?: Contentful.ContentfulEntry[]): SocialLinkProps[] {
  const items = entries?.filter(isSocialLink) ?? [];
  return items.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulSocialLink): SocialLinkProps {
  return {
    id: entry.id ?? '',
    icon: entry.icon ?? '',
    url: entry.url ?? '',
  };
}

interface AgeGateQuery {
  ageGate: ContentModels.ContentfulAgeGate;
  badge?: {
    childCloudinaryAsset?: {
      fixed?: FixedObject;
    };
  };
}

function useAgeGateQuery(): AgeGateQuery {
  return useStaticQuery<AgeGateQuery>(graphql`
    query AgeGateQuery {
      ageGate: contentfulAgeGate {
        title
        content {
          content
        }
        ctaText
        socialLinks {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          ... on ContentfulSocialLink {
            icon
            title
            url
          }
        }
      }
      badge: file(name: { eq: "sot-badge" }) {
        name
        childCloudinaryAsset {
          fixed(width: 198, height: 198, transformations: "f_auto,q_auto") {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
    }
  `);
}
