import { Variants } from 'framer-motion';
import React from 'react';
import s from './Navicon.styles';

export interface NaviconProps {
  active: boolean;
}

const Navicon: React.FC<NaviconProps> = ({ active }) => {
  const wrapperVariants: Variants = {
    inactive: {},
    active: {},
  };

  const duration = 0.6;
  const ease = 'easeOut';

  const transition = {
    duration,
    ease,
  };

  const bar1Variants: Variants = {
    inactive: {
      rotate: [-45, 0, 0],
      y: [0, 0, -9],
      transition: transition,
    },
    active: {
      rotate: [0, 0, -45],
      y: [-9, 0, 0],
      transition: transition,
    },
  };

  const bar2Variants: Variants = {
    inactive: {
      opacity: [0, 0, 1],
      y: -1,
      transition: transition,
    },
    active: {
      opacity: [1, 0, 0],
      y: -1,
      transition: transition,
    },
  };

  const bar3Variants: Variants = {
    inactive: {
      rotate: [45, 0, 0],
      y: [0, 0, 7],
      transition: transition,
    },
    active: {
      rotate: [0, 0, 45],
      y: [7, 0, 0],
      transition: transition,
    },
  };

  return (
    <s.Wrapper
      initial="inactive"
      animate={active ? 'active' : 'inactive'}
      variants={wrapperVariants}
    >
      <s.Bar initial="inactive" variants={bar1Variants} />
      <s.Bar initial="inactive" variants={bar2Variants} />
      <s.Bar initial="inactive" variants={bar3Variants} />
    </s.Wrapper>
  );
};

export default Navicon;
