import bgPaperTiled from '@img/paper-bg-tiled.jpg';
import CarouselButton from '@presentation/CarouselButton';
import { Device, from } from '@utils/media';
import scrollbar from '@utils/scrollbar';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';

export interface ContainerProps {
  active: boolean;
}

const Container = styled.section<ContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  display: grid;
  height: 100%;
  pointer-events: none;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    backdrop-filter: blur(2px);
    background: ${theme.brand.grey.default};
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    height: 100%;
    transition: opacity 0.3s ease-out;
    width: 100vw;
    z-index: 1;
  }

  ${({ active }) =>
    active &&
    css`
      pointer-events: all;

      &::before {
        opacity: 0.7;
      }

      ${Inner} {
        transform: translate3d(0, 0, 0);
      }
    `}
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${theme.brand.paper.default} url(${bgPaperTiled});
  background-repeat: repeat;
  border-left: 1px solid ${theme.brand.grey.default};
  height: 100%;
  grid-column: 1;
  grid-row: 1;
  max-width: 520px;
  min-height: 0;
  padding: 24px;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s ease-out;
  width: 90vw;
  z-index: 2;

  @media ${from(Device.Tablet)} {
    padding: 48px;
  }
`;

const Close = styled(CarouselButton)`
  position: absolute;
  top: 24px;
  left: 24px;
  height: 60px;
  flex-basis: 60px;
  width: 60px;

  @media ${from(Device.Tablet)} {
    top: 48px;
    left: 48px;
  }
`;

const Title = styled.h2`
  color: ${theme.brand.blue.dark};
  font-size: 30px;
  line-height: 60px;
  margin-bottom: 48px;
  padding-left: 96px;
  text-transform: uppercase;
`;

const ItemScroll = styled.div`
  ${scrollbar};
  margin-bottom: 16px;
  overflow-y: auto;
`;

const ItemList = styled.div`
  display: block;
`;

const Item = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 100px auto;
  grid-template-rows: 100px auto;
  margin-bottom: 32px;
  min-width: 0;

  @media ${from(Device.MobileLarge)} {
    grid-template-columns: 100px auto auto;
    grid-template-rows: auto auto;
  }
`;

const ItemImage = styled.div`
  display: block;
  height: 100%;
  grid-column: 1;
  grid-row: 1 / span 2;
  min-width: 0;
  width: 100%;

  @media ${from(Device.MobileLarge)} {
    grid-column: 1;
    grid-row: 1 / span 2;
  }
`;

const ItemTitle = styled.h3`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.venusian};
  font-size: 18px;
  grid-column: 2 / span 1;
  grid-row: 1;
  letter-spacing: 0.02em;
  line-height: 26px;
  min-width: 0;
  text-transform: uppercase;

  @media ${from(Device.MobileLarge)} {
    grid-column: 2 / span 2;
    grid-row: 1;
  }
`;

const ItemVariantTitle = styled.small`
  display: block;
  color: ${theme.brand.blue.dark};
  ${theme.fonts.venusian};
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 18px;
  min-width: 0;
  text-transform: uppercase;
`;

const ItemQuantityWrapper = styled.div`
  display: grid;
  grid-column: 1;
  grid-row: 2;
  grid-template-columns: 46px 1fr 46px;
  min-width: 0;

  @media ${from(Device.MobileLarge)} {
    grid-column: 2;
    grid-row: 2;
  }
`;

const ItemQuantity = styled.input`
  position: relative;
  appearance: none;
  background: ${theme.brand.paper.default};
  border: 2px solid ${theme.brand.paper.dark};
  border-radius: 18px;
  color: ${theme.brand.blue.default};
  flex: 1 1 50%;
  ${theme.fonts.venusian};
  font-size: 22px;
  grid-column: 1 / span 3;
  grid-row: 1;
  height: 36px;
  line-height: 25px;
  min-width: 0;
  padding: 8px 16px;
  text-align: center;
  width: auto;
  z-index: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  @media ${from(Device.TabletLarge)} {
    border-radius: 18px;
    font-size: 22px;
    height: 36px;
    line-height: 25px;
    max-width: 246px;
  }
`;

const Increment = styled.button`
  position: relative;
  background: none;
  border: 0;
  color: ${theme.brand.blue.default};
  cursor: pointer;
  ${theme.fonts.brandon.bold};
  font-size: 20px;
  grid-column: 3;
  grid-row: 1;
  height: 36px;
  line-height: 1em;
  margin: 0;
  padding: 8px 16px;
  z-index: 2;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
  }
`;

const Decrement = styled.button`
  position: relative;
  background: none;
  border: 0;
  color: ${theme.brand.blue.default};
  cursor: pointer;
  ${theme.fonts.brandon.bold};
  font-size: 20px;
  grid-column: 1;
  grid-row: 1;
  height: 36px;
  line-height: 1em;
  padding: 8px 16px;
  transform: translateY(-2px);
  z-index: 2;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
  }
`;

const Remove = styled.button`
  display: block;
  background: none;
  border: 0;
  ${theme.fonts.brandon.bold};
  color: ${theme.brand.blue.dark};
  cursor: pointer;
  font-size: 16px;
  grid-column: 1 / span 3;
  grid-row: 2;
  line-height: 23px;
  margin: 8px auto 0;
  text-transform: uppercase;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const ItemPrice = styled.div`
  color: ${theme.brand.red.dark};
  ${theme.fonts.venusian};
  font-size: 20px;
  grid-column: 2;
  grid-row: 2;
  letter-spacing: 0.02em;
  line-height: 20px;
  min-width: 0;
  padding: 8px 0 0;

  @media ${from(Device.MobileLarge)} {
    grid-column: 3;
    grid-row: 2;
  }
`;

const Subtotal = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.brand.blue.dark};
  flex-direction: row;
  ${theme.fonts.venusian};
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 8px;
`;

const SubtotalLabel = styled.div`
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 20px;
  text-transform: uppercase;
`;

const SubtotalValue = styled.div`
  color: ${theme.brand.red.dark};
  font-size: 30px;
  letter-spacing: 0.02em;
  line-height: 34px;
`;

const Content = styled.div`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.medium};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 28px;
  margin-bottom: 16px;
`;

const Empty = styled.div`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.medium};
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 30px;
  margin-bottom: 16px;
`;

export default {
  Close,
  Container,
  Content,
  Decrement,
  Empty,
  Increment,
  Inner,
  Item,
  ItemImage,
  ItemList,
  ItemQuantity,
  ItemQuantityWrapper,
  ItemPrice,
  ItemScroll,
  ItemTitle,
  ItemVariantTitle,
  Remove,
  Subtotal,
  SubtotalLabel,
  SubtotalValue,
  Title,
};
