import NewsletterSignUpForm from '@presentation/NewsletterSignUpForm';
import { MARKETING_COOKIE_NAME } from '@utils/constants';
import Cookie from 'js-cookie';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useBoolean } from 'react-use';
import s from './NewsletterSignUp.styles';

export interface NewsletterSignUpProps {
  checkboxLabel?: string;
  confirmationContent: string;
  formContent?: string;
  title?: string;
  subtitle?: string;
}

const NewsletterSignUp: React.FC<NewsletterSignUpProps> = ({
  checkboxLabel = '',
  confirmationContent,
  formContent,
  subtitle,
  title,
}) => {
  const [submitted, toggleSubmitted] = useBoolean(false);

  return (
    <s.Wrapper>
      <s.TitleContainer>
        {title && <s.Title>{title}</s.Title>}
        {subtitle && <s.Subtitle>{subtitle}</s.Subtitle>}
      </s.TitleContainer>
      {submitted ? (
        <s.Content>
          <Markdown>{confirmationContent}</Markdown>
        </s.Content>
      ) : (
        <NewsletterSignUpForm
          checkboxLabel={checkboxLabel}
          content={formContent}
          idPrefix="footer"
          onSuccess={() => {
            Cookie.set(MARKETING_COOKIE_NAME, '1');
            toggleSubmitted(true);
          }}
        />
      )}
    </s.Wrapper>
  );
};

export default NewsletterSignUp;
