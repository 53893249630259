import React from 'react';
import s from './ContentFrame.styles';

export interface ContentFrameProps {}

const ContentFrame: React.FC<ContentFrameProps> = ({ children }) => {
  return (
    <>
      <s.Svg>
        <clipPath id="content-frame" clipPathUnits="objectBoundingBox">
          <path d="M0.981,0 H0.019 a0.007,0.013,0,0,0,-0.006,0.012 l-0.001,0.013 a0.005,0.009,0,0,1,-0.001,0.004 L0.01,0.029,0.005,0.032 A0.007,0.014,0,0,0,0,0.045 V0.955 a0.007,0.014,0,0,0,0.005,0.013 l0.006,0.003,0,0.001 a0.005,0.009,0,0,1,0.001,0.004 l0.001,0.013 a0.007,0.013,0,0,0,0.006,0.012 H0.981 a0.007,0.013,0,0,0,0.006,-0.011 l0.001,-0.013 a0.005,0.009,0,0,1,0.001,-0.004 l0,-0.001,0.006,-0.003 a0.007,0.014,0,0,0,0.005,-0.013 V0.045 a0.007,0.014,0,0,0,-0.005,-0.013 L0.989,0.029 a0.001,0.001,0,0,1,0,-0.001 a0.005,0.009,0,0,1,-0.001,-0.004 l-0.001,-0.013 A0.007,0.013,0,0,0,0.981,0"></path>
        </clipPath>
      </s.Svg>
      <s.FrameOuterBorder>
        <s.FrameInner>
          <s.FrameInnerBorder>
            <s.FrameContentWrapper>{children}</s.FrameContentWrapper>
          </s.FrameInnerBorder>
        </s.FrameInner>
      </s.FrameOuterBorder>
    </>
  );
};

export default ContentFrame;
