export default function formatPrice(value: number, isPence = false): string {
  const formatter = new Intl.NumberFormat('en-GB', {
    currency: 'GBP',
    style: 'currency',
  });

  if (isPence) {
    value = value / 100;
  }

  return formatter.format(value);
}
