import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import theme from '@utils/theme';
import { Device, from } from '@utils/media';

const ClipPath = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`;

const Wrapper = styled.footer<{ alternate: boolean }>`
  position: relative;
  background: ${theme.brand.blue.dark};

  &::before {
    content: '';
    position: absolute;
    top: -40px;
    left: 0;
    display: block;
    background: ${theme.brand.blue.dark};
    clip-path: url(#footer-clip-path);
    height: 50px;
    width: 100%;

    @media ${from(Device.Tablet)} {
      top: -120px;
      height: 150px;
    }
  }

  @media ${from(Device.Tablet)} {
    ${({ alternate }) =>
      alternate &&
      css`
        background: none;
        padding-top: 16vw;

        &::before {
          content: none;
        }

        ${BackgroundImage} {
          .gatsby-image-wrapper {
            margin-top: 0;
            height: 100%;
          }

          &::after {
            content: none;
          }
        }

        ${ContentWrapper} {
          margin-top: 10vw;
        }
      `}
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  .gatsby-image-wrapper {
    margin-top: 26vw;
    height: calc(100% - 26vw);
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 26vw;
    left: 0;
    background: linear-gradient(to bottom, rgba(14, 77, 143, 1) 0%, rgba(14, 77, 143, 0) 35%);
    height: calc(100% - 26vw);
    width: 100%;
    z-index: 1;
  }
`;

const NewsletterWrapper = styled.div`
  position: relative;
  padding: 96px 0 0;
  z-index: 2;

  @media ${from(Device.Tablet)} {
    padding: 200px 0 0;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  background: linear-gradient(to bottom, rgba(14, 77, 143, 0), rgba(14, 77, 143, 0.5));
  margin: 26vw 0 0;
  padding: 10vw 0 80px;
  z-index: 2;
`;

const StyledSiteWide = styled(SiteWide)``;

const LinkListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;

  @media ${from(Device.TabletLarge)} {
    flex-direction: row;
    place-content: space-around;
  }

  ${() => LinkList} {
    margin: 0 16px 32px;
  }
`;

export interface LinkListProps {
  layout?: 'vertical' | 'horizontal';
  weight?: 'regular' | 'bold';
}

const LinkList = styled.div<LinkListProps>`
  display: flex;

  ${({ layout }) => {
    switch (layout) {
      case 'horizontal':
        return css`
          flex-direction: column;
          text-align: center;

          ${LinkItem} {
            margin: 8px;
          }

          @media ${from(Device.TabletLarge)} {
            flex-direction: row;
            text-align: left;

            ${LinkItem} {
              margin: 0 24px;
            }
          }
        `;

      case 'vertical':
        return css`
          flex-direction: column;
          text-align: center;

          ${LinkItem} {
            margin: 8px;
          }
        `;
    }
  }}

  ${({ weight }) => {
    switch (weight) {
      case 'regular':
        return css`
          ${StyledLink} {
            font-weight: 400;
          }
        `;

      case 'bold':
        return css`
          ${StyledLink} {
            font-weight: 700;
          }
        `;
    }
  }}
`;

const LinkItem = styled.div``;

interface StyledLinkProps {
  isTitle: boolean;
}

const StyledLink = styled(GatsbyLink)<StyledLinkProps>`
  color: ${theme.brand.white.default};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 36px;
  }

  ${({ isTitle }) =>
    isTitle &&
    css`
      ${theme.fonts.venusian};
      font-size: 18px;
      letter-spacing: 0.02em;

      @media ${from(Device.TabletLarge)} {
        font-size: 22px;
        letter-spacing: 0.02em;
      }
    `}

  &:not(span) {
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;

const LegalText = styled.span`
  color: ${theme.brand.white.default};
  ${theme.fonts.brandon.regular};
  font-size: 16px;
  letter-spacing: 0.016em;
  line-height: 23px;
  margin-top: 24px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 36px;
  }

  @media ${from(Device.Desktop)} {
    margin: 0;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  text-align: center;

  @media ${from(Device.Desktop)} {
    flex-direction: row;
    text-align: left;
  }
`;

export default {
  BackgroundImage,
  ClipPath,
  ContentWrapper,
  FooterBottom,
  LegalText,
  Link: StyledLink,
  LinkItem,
  LinkList,
  LinkListWrapper,
  NewsletterWrapper,
  SiteWide: StyledSiteWide,
  Wrapper,
};
