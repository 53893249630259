import { Button } from '@presentation/Button';
import formatPrice from '@utils/formatPrice';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import s from './Basket.styles';

export interface BasketItemModel {
  id: string;
  itemTotal: number;
  quantity: number;
  thumbnail?: string;
  title: string;
  variantTitle?: string;
}

export interface BasketItemProps extends BasketItemModel {
  onUpdate: (id: string, quantity: number) => void;
  onRemove: (id: string) => void;
}

export interface BasketProps {
  active: boolean;
  content: string;
  items: BasketItemModel[];
  subtotal: number;
  onCheckout: () => void;
  onClose: () => void;
  onItemUpdate: (id: string, quantity: number) => void;
  onItemRemove: (id: string) => void;
}

const BasketItem: React.FC<BasketItemProps> = ({
  id,
  itemTotal,
  quantity: initialQuantity,
  thumbnail,
  title,
  variantTitle,
  onUpdate,
  onRemove,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const [debouncedQuantity, setDebouncedQuantity] = useState(initialQuantity);

  useDebounce(
    () => {
      if (quantity !== debouncedQuantity) {
        setDebouncedQuantity(quantity);
        onUpdate(id, quantity);
      }
    },
    500,
    [quantity],
  );

  // Ensure quantity is kept synchronised with redux store.
  useEffect(() => {
    if (initialQuantity !== quantity) {
      setQuantity(initialQuantity);
      setDebouncedQuantity(initialQuantity);
    }
  }, [initialQuantity]);

  return (
    <s.Item>
      <s.ItemImage>
        {thumbnail && <img alt="" src={thumbnail} role="presentation" height="100" width="100" />}
      </s.ItemImage>
      <s.ItemTitle>
        {title}
        {variantTitle && <s.ItemVariantTitle>{variantTitle}</s.ItemVariantTitle>}
      </s.ItemTitle>
      <s.ItemQuantityWrapper>
        <s.Increment
          onClick={(event) => {
            event.preventDefault();
            setQuantity(quantity + 1);
          }}
        >
          {'+'}
        </s.Increment>
        <s.Decrement
          onClick={(event) => {
            event.preventDefault();
            setQuantity(Math.max(quantity - 1, 1));
          }}
        >
          {'-'}
        </s.Decrement>
        <s.ItemQuantity
          min={1}
          type="number"
          value={quantity}
          onChange={(event) => {
            setQuantity(parseInt(event.target.value, 10));
          }}
        />
        <s.Remove
          onClick={(event) => {
            event.preventDefault();
            onRemove(id);
          }}
        >
          Remove
        </s.Remove>
      </s.ItemQuantityWrapper>
      <s.ItemPrice>{formatPrice(itemTotal)}</s.ItemPrice>
    </s.Item>
  );
};

const Basket: React.FC<BasketProps> = ({
  active,
  content,
  items,
  subtotal,
  onCheckout,
  onClose,
  onItemRemove,
  onItemUpdate,
}) => {
  const isEmpty = !items.length;

  return (
    <s.Container active={active}>
      <s.Inner>
        <s.Close
          direction="prev"
          size="medium"
          onClick={(event) => {
            event.preventDefault();
            onClose();
          }}
        >
          Close
        </s.Close>
        <s.Title>Basket</s.Title>
        {!isEmpty ? (
          <>
            <s.ItemScroll>
              <s.ItemList>
                {items.map((item) => (
                  <BasketItem
                    key={item.id}
                    {...item}
                    onRemove={onItemRemove}
                    onUpdate={onItemUpdate}
                  />
                ))}
              </s.ItemList>
            </s.ItemScroll>
            <s.Subtotal>
              <s.SubtotalLabel>Subtotal</s.SubtotalLabel>
              <s.SubtotalValue>{formatPrice(subtotal)}</s.SubtotalValue>
            </s.Subtotal>
            <s.Content>{content}</s.Content>
            <Button
              onClick={(event) => {
                event.preventDefault();
                onCheckout();
              }}
            >
              Checkout
            </Button>
          </>
        ) : (
          <s.Empty>Your basket is currently empty.</s.Empty>
        )}
      </s.Inner>
    </s.Container>
  );
};

export default Basket;
