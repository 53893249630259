import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: block;
  background: ${theme.brand.blue.dark};
  color: ${theme.brand.white.default};
  z-index: 10;
`;

const StyledSiteWide = styled(SiteWide)`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${from(Device.TabletLarge)} {
    flex-direction: row;
    text-align: left;
  }
`;

const Text = styled.div`
  white-space: normal;
  ${markdownStyles};
  display: block;
  ${theme.fonts.brandon.bold};
  font-size: 12px;
  line-height: 1.2em;
  text-transform: uppercase;

  strong {
    color: ${theme.brand.gold.default};
    ${theme.fonts.brandon.bold};
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 16px;
    letter-spacing: 0.02em;
  }
`;

const Separator = styled(Text)`
  display: none;

  @media ${from(Device.TabletLarge)} {
    display: block;
    margin: 0 12px;
  }
`;

const DesktopOnlyText = styled(Text)`
  ${markdownStyles};
  display: none;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

export default {
  DesktopOnlyText,
  Separator,
  SiteWide: StyledSiteWide,
  Text,
  Wrapper,
};
