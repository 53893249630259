import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ShopifyBuy } from 'shopify';
import client from '../client';

type AddToCartParams = { variantId: string; quantity: number };

type ThunkApiType = {
  state: RootState;
};

const TypePrefix = 'shopify/addToCart';

type ThunkType = AsyncThunk<ShopifyBuy.Cart | undefined, AddToCartParams, ThunkApiType>;

const addToCart: ThunkType = createAsyncThunk(
  TypePrefix,
  async ({ variantId, quantity }, thunkApi) => {
    const state = thunkApi.getState();

    const checkoutId = state.shopify.checkout?.id;

    if (!checkoutId) {
      return undefined;
    }

    const cart = await client.checkout.addLineItems(checkoutId, [{ variantId, quantity }]);

    // Ensure we return a plain object.
    return JSON.parse(JSON.stringify(cart));
  },
);

export default addToCart;
