import ExitIntent from '@presentation/ExitIntent';
import NewsletterSignUpForm from '@presentation/NewsletterSignUpForm';
import { MARKETING_COOKIE_NAME } from '@utils/constants';
import Cookie from 'js-cookie';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useBoolean } from 'react-use';
import s from './NewsletterSignUpModal.styles';

export interface NewsletterSignUpModalProps {
  checkboxLabel: string;
  confirmationContent: string;
  content: string;
  formContent?: string;
  title: string;
}

const NewsletterSignUpModal: React.FC<NewsletterSignUpModalProps> = ({
  checkboxLabel,
  confirmationContent,
  content,
  formContent,
  title,
}) => {
  const [submitted, toggleSubmitted] = useBoolean(false);

  return (
    <ExitIntent cookieName={MARKETING_COOKIE_NAME}>
      <s.Container>
        {title && <s.Title>{title}</s.Title>}
        {submitted ? (
          <s.Content>
            <Markdown>{confirmationContent}</Markdown>
          </s.Content>
        ) : (
          <>
            {content && (
              <s.Content>
                <Markdown>{content}</Markdown>
              </s.Content>
            )}
            <NewsletterSignUpForm
              checkboxLabel={checkboxLabel}
              content={formContent}
              idPrefix="modal"
              onSuccess={() => {
                Cookie.set(MARKETING_COOKIE_NAME, '1');
                toggleSubmitted(true);
              }}
            />
          </>
        )}
      </s.Container>
    </ExitIntent>
  );
};

export default NewsletterSignUpModal;
