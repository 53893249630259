import bgPaperTiled from '@img/paper-bg-tiled.jpg';
import SvgStamp from '!babel-loader!react-svg-loader!@img/sot-stamp.svg';
import ImageFrame from '@presentation/ImageFrame';
import imageFrameStyles from '@presentation/ImageFrame/ImageFrame.styles';
import { Device, from, until } from '@utils/media';
import theme from '@utils/theme';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { markdownStyles } from '@utils/markdown';

const Wrapper = styled(motion.div)`
  padding: 158px 0 224px;

  @media ${from(Device.TabletLarge)} {
    padding: 224px 0 224px;
  }
`;

const Container = styled.div`
  display: block;

  /* Can't use a clip path on mobile without it being stretched due to content. */
  @media ${until(Device.TabletLarge)} {
    ${imageFrameStyles.FrameOuterBorder},
    ${imageFrameStyles.FrameInner} {
      clip-path: none !important;
      clip-path: none !important;
    }
  }

  ${imageFrameStyles.FrameInnerBorder} {
    margin: 5%;

    @media ${from(Device.TabletLarge)} {
      margin: 3%;
    }
  }
`;

const Frame = styled(ImageFrame)``;

const Inner = styled.div`
  display: flex;
  background: ${theme.brand.paper.default} url(${bgPaperTiled});
  background-repeat: repeat;
  flex-direction: column;
  padding: 32px;

  @media ${from(Device.TabletLarge)} {
    padding: 64px;
  }

  @media ${from(Device.DesktopSmall)} {
    flex-direction: row;
  }
`;

const ContentColumn = styled.div`
  flex: 1 1 50%;
  min-width: 0;
`;

const FormColumn = styled.div`
  flex: 1 1 50%;
  padding: 48px 0;
`;

const FormWrapper = styled(motion.div)``;

const Separator = styled(motion.div)`
  display: block;
  background-color: transparent;
  background-image: linear-gradient(
    to bottom,
    ${theme.brand.blue.default},
    ${theme.brand.blue.default}
  );
  background-repeat: no-repeat;
  background-size: 100% 0%;
  border: 0;
  height: 3px;
  margin: 24px 0;
  width: 100%;

  @media ${from(Device.DesktopSmall)} {
    height: auto;
    margin: 0 64px;
    width: 5px;
  }
`;

const StampWrapper = styled(motion.div)`
  margin: 0 0 8px;
  max-width: 265px;
  width: 50%;

  @media ${from(Device.TabletLarge)} {
    margin: 0 0 16px;
  }
`;

const Stamp = styled(SvgStamp)`
  color: ${theme.brand.red.default};
`;

const Title = styled(motion.h2)`
  color: ${theme.brand.blue.default};
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 16px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 60px;
    line-height: 85px;
  }
`;

const ContactDetail = styled(motion.p)`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 15px;
  letter-spacing: 0.02em;
  line-height: 24px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
  }
`;

const ContactDetailLink = styled.a`
  display: inline-block;
  color: ${theme.brand.blue.dark};
  transition: color 0.3s ease-out;

  &:focus,
  &:hover {
    color: ${theme.brand.red.default};
  }
`;

const Subtitle = styled(motion.h3)`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 15px;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin-top: 24px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
    margin-top: 32px;
  }
`;

const SocialLinks = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 8px -8px 0;
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
  }
`;

export default {
  ContactDetail,
  ContactDetailLink,
  Container,
  Content,
  ContentColumn,
  FormColumn,
  FormWrapper,
  Frame,
  Inner,
  Separator,
  SocialLinks,
  Stamp,
  StampWrapper,
  Subtitle,
  Title,
  Wrapper,
};
