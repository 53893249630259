import theme from '@utils/theme';
import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import { Reset } from 'styled-reset';
import bgPaperTiled from '@img/paper-bg-tiled.jpg';
import scrollbar from '@utils/scrollbar';

const CustomGlobalStyles = createGlobalStyle`
  html {
    background: ${theme.brand.paper.default} url(${bgPaperTiled});
    background-repeat: repeat;
    box-sizing: border-box;
    color: ${theme.brand.blue.dark};
    ${theme.fonts.brandon.medium};
    ${scrollbar};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.brand.blue.default};
    ${theme.fonts.venusian};
  }

  /* Show focus styles on keyboard focus. */
  :focus-visible {
    outline: auto;
    outline-offset: 5px;
  }

  /* Hide focus styles if they're not needed, for example,
  when an element receives focus via the mouse. */
  :focus:not(:focus-visible) {
    outline: 0;
  }
`;

const GlobalStyles: React.FC = () => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/fonts.css" />
      </Helmet>
      <Reset />
      <CustomGlobalStyles />
    </>
  );
};

export default GlobalStyles;
