import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ShopifyBuy } from 'shopify';
import client from '../client';

type UpdateInCartParams = { lineItemId: string; quantity: number };

type ThunkApiType = {
  state: RootState;
};

const TypePrefix = 'shopify/updateInCart';

type ThunkType = AsyncThunk<ShopifyBuy.Cart | undefined, UpdateInCartParams, ThunkApiType>;

const updateInCart: ThunkType = createAsyncThunk(
  TypePrefix,
  async ({ lineItemId, quantity }, thunkApi) => {
    const state = thunkApi.getState();

    const checkoutId = state.shopify.checkout?.id;

    if (!checkoutId) {
      return undefined;
    }

    const cart = await client.checkout.updateLineItems(checkoutId, [{ id: lineItemId, quantity }]);

    // Ensure we return a plain object.
    return JSON.parse(JSON.stringify(cart));
  },
);

export default updateInCart;
