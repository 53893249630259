import ContentFrame from '@presentation/ContentFrame';
import React from 'react';
import s from './Modal.styles';

export interface ModalProps {
  active: boolean;
  onClose: () => void;
}

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(({ active, children, onClose }, ref) => {
  return (
    <s.Container ref={ref} active={active}>
      <s.Outer>
        <ContentFrame>
          <s.Close onClick={onClose}>
            <s.CloseIcon />
            <s.CloseText>Close</s.CloseText>
          </s.Close>
          {children}
        </ContentFrame>
      </s.Outer>
    </s.Container>
  );
});

Modal.displayName = 'Modal';

export interface ExitIntentProps {}

export default Modal;
