import { css, FlattenSimpleInterpolation } from 'styled-components';

export default function placeholder(
  placeholderStyles: FlattenSimpleInterpolation,
): FlattenSimpleInterpolation {
  return css`
    ::placeholder,
    ::-webkit-input-placeholder {
      ${placeholderStyles};
    }
    :-ms-input-placeholder {
      ${placeholderStyles};
    }
  `;
}
