import IconClose from '!babel-loader!react-svg-loader!@img/icon-close.svg';
import IconPlay from '!babel-loader!react-svg-loader!@img/icon-play.svg';
import bgTiled from '@img/stressed-bg-tiled.jpg';
import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import plyrStyles from '@utils/plyrStyles';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 158px;

  @media ${from(Device.TabletLarge)} {
    padding-top: 224px;
  }
`;

const ItemContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
  min-width: 0;
  text-align: center;
  transition: opacity 0.3s ease-out;

  @media ${from(Device.DesktopSmall)} {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }
`;

const ItemContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
  min-width: 0;
  padding: 36px 0;

  @media ${from(Device.DesktopSmall)} {
    align-items: flex-start;
    padding-bottom: 128px;
    place-content: center;
  }
`;

const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-width: 620px;
`;

const ItemTitle = styled(motion.h2)`
  font-size: 40px;
  font-weight: 400;
  line-height: 45px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 60px;
    font-weight: 400;
    line-height: 68px;
    margin-bottom: 24px;
  }
`;

const ItemSubtitle = styled(motion.h3)`
  color: ${theme.brand.red.default};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 16px;
  }
`;

const ItemContent = styled(motion.div)`
  ${markdownStyles};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 22px;
  margin-bottom: 32px;
  max-width: 560px;
  text-transform: uppercase;
`;

const ItemCTAs = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 -12px;

  @media ${from(Device.DesktopSmall)} {
    align-items: stretch;
    flex-direction: row;
  }
`;

const ItemImage = styled(motion.div)`
  position: relative;
  display: flex;
  border-radius: 5px;
  grid-column: 2;
  grid-row: 1;
  margin: 0 auto;
  max-width: 800px;
  place-content: center;
  place-items: center;
  width: 100%;
  z-index: 1;

  .gatsby-image-wrapper {
    width: 100%;
  }

  img {
    border-radius: 5px;
  }
`;

const ItemVideo = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: ${theme.brand.black.default};
  border-radius: 5px;
  place-content: center;
  place-items: center;
  z-index: 100;
`;

const ItemVideoWrapper = styled.div`
  ${plyrStyles};
  width: 100%;
`;

const ItemVideoClose = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 4;
  display: flex;
  background: transparent url(${bgTiled});
  background-repeat: repeat;
  border: 0;
  border-radius: 50%;
  color: ${theme.brand.white.default};
  cursor: pointer;
  height: 48px;
  place-content: center;
  place-items: center;
  margin: 0;
  outline: 0;
  padding: 0;
  transition: filter 0.3s ease-out, transform 0.3s ease-out;
  width: 48px;
  will-change: transform;
  z-index: 2;

  &:focus,
  &:hover {
    filter: brightness(1.35);
    transform: translate3d(0, -2px, 0);
  }
`;

const ItemVideoCloseIcon = styled(IconClose)`
  display: block;
  height: 50%;
  width: 50%;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  background: transparent url(${bgTiled});
  background-repeat: repeat;
  border: 0;
  border-radius: 50%;
  color: ${theme.brand.white.default};
  cursor: pointer;
  height: 162px;
  place-content: center;
  place-items: center;
  margin: 0;
  outline: 0;
  padding: 0;
  transform: translate3d(-50%, -50%, 0);
  transition: filter 0.3s ease-out, transform 0.3s ease-out;
  width: 162px;
  will-change: transform;
  z-index: 2;

  &:focus,
  &:hover {
    filter: brightness(1.35);
    transform: translate3d(-50%, -50%, 0) translate3d(0, -2px, 0);
  }
`;

const PlayIcon = styled(IconPlay)`
  display: block;
`;

const Pagination = styled.div`
  margin: 24px 0 64px;
  text-align: center;

  @media ${from(Device.DesktopSmall)} {
    position: absolute;
    left: 0;
    bottom: 80px;
    margin: 0 -5px;
    text-align: left;
  }
`;

const PaginationItem = styled.button<{ active: boolean }>`
  display: inline-block;
  background: ${theme.brand.blue.dark};
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 17px;
  margin: 0 5px;
  outline: 0;
  padding: 0;
  text-indent: -200vw;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  width: 17px;

  &:focus,
  &:hover {
    opacity: 0.75;
    transform: scale(1.2);
  }

  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.5;
        `}
`;

const TurtleImage = styled.div`
  position: absolute;
  top: 32px;
  left: calc(var(--site-padding) * -1);
  opacity: 0.1;
  pointer-events: none;
  transform: translate3d(-40%, 0, 0);

  @media ${from(Device.DesktopSmall)} {
    transform: translate3d(0, 0, 0);
  }
`;

export default {
  Container,
  ItemContainer,
  ItemContent,
  ItemContentContainer,
  ItemCTAs,
  ItemImage,
  ItemSubtitle,
  ItemTitle,
  ItemTitleContainer,
  ItemVideo,
  ItemVideoClose,
  ItemVideoCloseIcon,
  ItemVideoWrapper,
  Pagination,
  PaginationItem,
  PlayButton,
  PlayIcon,
  TurtleImage,
};
