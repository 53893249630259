import React from 'react';
import s from './TextBox.styles';

export interface TextBoxProps extends ReactInputProps {
  hasError: boolean;
}

const TextBox = React.forwardRef<HTMLInputElement, TextBoxProps>(
  ({ hasError, ...otherProps }, ref) => {
    return <s.Input ref={ref} invalid={hasError} {...otherProps} />;
  },
);

TextBox.displayName = 'TextBox';

export default TextBox;
