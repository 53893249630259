import { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { Device, from, until } from '@utils/media';
import srOnly from '@utils/srOnly';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';

export interface WrapperProps {
  notification: boolean;
  small: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  --logo-offset-y: -92px;

  position: fixed;
  top: 0;
  right: 0;
  height: 96px;
  transition: height 0.3s ease-out, transform 0.3s ease-out;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    height: 191px;
  }

  ${({ notification }) =>
    notification &&
    css`
      --logo-offset-y: calc(-92px - 46px);
      transform: translate3d(0, 46px, 0);

      @media ${from(Device.TabletLarge)} {
        --logo-offset-y: calc(-92px - 51px);
        transform: translate3d(0, 51px, 0);
      }
    `}

  ${({ small }) =>
    small &&
    css`
      pointer-events: none;

      @media ${from(Device.TabletLarge)} {
        height: 60px;
      }

      ${Logo} {
        transform: translate3d(-50%, -50%, 0) translate3d(0, var(--logo-offset-y), 0);
      }

      ${CtaContainer} {
        pointer-events: all;
      }
    `}
`;

const StyledSiteWide = styled(SiteWide)`
  height: 100%;
`;

const Inner = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
`;

const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  max-width: 250px;
  transform: translate3d(-50%, -50%, 0);
  transition: transform 0.3s ease-out;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    max-width: 398px;
  }
`;

const LogoWrapper = styled(Wrapper)`
  z-index: 10;
`;

const CtaWrapper = styled(Wrapper)`
  pointer-events: none;
  z-index: 20;
`;

const CtaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  grid-column: 1;
  grid-row: 1;
  place-content: space-between;
  place-items: center;
  pointer-events: all;

  @media ${from(Device.TabletLarge)} {
    background: ${theme.brand.paper.default};
    border: 2px solid ${theme.brand.blue.default};
    border-radius: 5px;
    justify-self: flex-end;
    padding: 8px;
  }
`;

const sharedCtaStyles = css`
  display: grid;
  background: none;
  border: 0;
  color: ${theme.brand.blue.dark};
  cursor: pointer;
  ${theme.fonts.venusian};
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1em;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  transition: transform 0.3s ease-out;
  will-change: transform;

  &:focus {
    outline: auto;
    outline-offset: 5px;
  }

  &:focus,
  &:hover {
    transform: translate3d(0, -2px, 0);
  }

  &:not(:first-child) {
    margin-left: 32px;
  }
`;

const CtaNavigation = styled.button<{ active: boolean }>`
  ${sharedCtaStyles};
  grid-template-columns: auto;
  overflow: hidden;
  place-items: center;

  @media ${until(Device.TabletLarge)} {
    background: ${theme.brand.paper.default};
    border: 2px solid ${theme.brand.blue.default};
    border-radius: 5px;
    padding: 4px;
  }

  @media ${from(Device.TabletLarge)} {
    grid-template-columns: auto auto;
  }

  ${() => CtaText} {
    grid-column: 1;
    grid-row: 1;

    ${({ active }) =>
      active &&
      css`
        transform: translate3d(0, -23px, 0);
      `}
  }

  ${() => CtaTextActive} {
    grid-column: 1;
    grid-row: 1;

    ${({ active }) =>
      active &&
      css`
        transform: translate3d(0, 0, 0);
      `}
  }
`;

export interface CtaBasketProps {
  active: boolean;
}

const CtaBasket = styled.button<CtaBasketProps>`
  position: relative;
  ${sharedCtaStyles};
  grid-template-columns: auto;

  @media ${until(Device.TabletLarge)} {
    background: ${theme.brand.paper.default};
    border: 2px solid ${theme.brand.blue.default};
    border-radius: 5px;
    padding: 4px;
  }

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: attr(data-cart-count);
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        background: ${theme.brand.red.dark};
        border-radius: 50%;
        color: ${theme.brand.white.default};
        ${theme.fonts.brandon.bold};
        font-size: 12px;
        height: 18px;
        place-content: center;
        place-items: center;
        transform: translate3d(-40%, 40%, 0);
        width: 18px;
      }
    `}
`;

const CtaText = styled.span`
  display: block;
  margin-right: 12px;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-out;

  @media ${until(Device.TabletLarge)} {
    ${srOnly};
  }

  ${CtaBasket} & {
    ${srOnly};
  }
`;

const CtaTextActive = styled.span`
  display: block;
  margin-right: 12px;
  transform: translate3d(0, 23px, 0);
  transition: transform 0.3s ease-out;

  @media ${until(Device.TabletLarge)} {
    ${srOnly};
  }

  ${CtaBasket} & {
    ${srOnly};
  }
`;

const CtaIcon = styled.span`
  display: flex;
  place-content: center;
  place-items: center;
`;

export default {
  CtaBasket,
  CtaContainer,
  CtaIcon,
  CtaNavigation,
  CtaText,
  CtaTextActive,
  CtaWrapper,
  Inner,
  Logo,
  LogoWrapper,
  SiteWide: StyledSiteWide,
  Wrapper,
};
