import { AnimatePresence, Variants } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import s from './BasketNotification.styles';

export interface BasketNotificationProps {
  id?: string;
  title?: string;
  quantity?: number;
}

const BasketNotification: React.FC<BasketNotificationProps> = ({ id, quantity, title }) => {
  const [visible, setVisible] = useState(false);
  const [, , reset] = useTimeoutFn(hide, 5000);

  useEffect(() => {
    if (id) {
      setVisible(true);
      reset();
    }
  }, [id]);

  const notificatonVariants: Variants = {
    hidden: {
      opacity: 0,
      x: '100%',
      transition: {
        ease: 'easeOut',
        type: 'tween',
      },
    },
    visible: {
      opacity: 1,
      x: '0%',
      transition: {
        ease: 'easeOut',
        type: 'tween',
      },
    },
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {visible && (
        <s.Notification
          key={id}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={notificatonVariants}
        >
          <s.Title>{title}</s.Title>
          <s.Quantity>({quantity})</s.Quantity>
        </s.Notification>
      )}
    </AnimatePresence>
  );

  function hide(): void {
    setVisible(false);
  }
};

export default BasketNotification;
