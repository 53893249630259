import { createAsyncThunk } from '@reduxjs/toolkit';
import { ShopifyBuy } from 'shopify';
import client from '../client';
import { SHOPIFY_CHECKOUT_STORAGE_KEY } from '../constants';

const TypePrefix = 'shopify/initialiseCart';

const initialiseCart = createAsyncThunk<ShopifyBuy.Cart>(TypePrefix, async () => {
  const isBrowser = typeof window !== 'undefined';
  const existingCheckoutId = isBrowser ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY) : null;

  if (existingCheckoutId) {
    try {
      const cart = await client.checkout.fetch(existingCheckoutId);

      // Make sure this cart hasn’t already been purchased.
      if (!cart.completedAt) {
        return JSON.parse(JSON.stringify(cart));
      }
    } catch (e) {
      localStorage.removeItem(SHOPIFY_CHECKOUT_STORAGE_KEY);
    }
  }

  const newCart = await client.checkout.create();
  localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, newCart.id.toString());

  // Ensure we return a plain object.
  return JSON.parse(JSON.stringify(newCart));
});

export default initialiseCart;
