import { Device, from } from '@utils/media';
import placeholder from '@utils/placeholder';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';

const Input = styled.input<{ invalid: boolean }>`
  display: block;
  background: ${theme.brand.white.default};
  border: 2px solid ${theme.brand.blue.dark};
  border-radius: 10px;
  color: ${theme.brand.grey.dark};
  ${theme.fonts.brandon.bold};
  font-size: 18px;
  line-height: 1em;
  min-width: 0;
  max-width: none;
  padding: 20px;
  width: auto;

  ${placeholder(css`
    color: ${theme.brand.grey.default};
    text-transform: uppercase;
  `)}

  @media ${from(Device.Tablet)} {
    font-size: 24px;
    padding: 32px;
  }

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${theme.brand.red.dark};

      ${placeholder(css`
        color: ${theme.brand.red.dark};
      `)}
    `}
`;

export default {
  Input,
};
