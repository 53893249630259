import React, { useContext } from 'react';

export type PageContextValue = Record<string, unknown>;

const PageContext = React.createContext<PageContextValue>({});

export const usePageContext = (): PageContextValue => useContext(PageContext);

export interface PageContextProviderProps {
  context: PageContextValue;
}

const PageContextProvider: React.FC<PageContextProviderProps> = ({ children, context }) => {
  return <PageContext.Provider value={context}>{children}</PageContext.Provider>;
};

export default PageContextProvider;
