import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled(motion.span)`
  position: relative;
  display: block;
  height: 23px;
  width: 23px;
`;

const Bar = styled(motion.span)`
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  background: ${theme.brand.blue.dark};
  height: 2px;
  width: 100%;
`;

export default {
  Bar,
  Wrapper,
};
