import { css } from 'styled-components';
import theme from './theme';

export const markdownStyles = css`
  p {
    color: currentColor;
    margin: 0 0 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    background: linear-gradient(to right, #2067ad, #2067ad) no-repeat;
    background-position: 0 6px;
    background-size: 0% 75%;
    color: ${theme.brand.red.dark};
    margin: -4px;
    outline: 0;
    padding: 4px;
    text-decoration: none;
    transition: background-size 0.15s ease-out, color 0.15s ease-out;

    &:focus,
    &:hover {
      background-size: 100% 75%;
      color: ${theme.brand.white.default};
      outline: 0;
    }
  }
`;
