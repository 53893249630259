import modalStyles from '@presentation/NewsletterSignUpModal/NewsletterSignUpModal.styles';
import { Device, from } from '@utils/media';
import srOnly from '@utils/srOnly';
import theme from '@utils/theme';
import unicode from '@utils/unicode';
import styled, { css } from 'styled-components';

const Input = styled.input`
  ${srOnly};
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  place-items: flex-start;

  &::before {
    content: '';
    display: inline-flex;
    border: 2px solid ${theme.brand.white.default};
    color: ${theme.brand.white.default};
    height: 20px;
    flex: 0 0 20px;
    font-size: 14px;
    margin-top: 0;
    margin-right: 16px;
    place-content: center;
    place-items: center;
    width: 20px;

    ${() => Input}:checked + & {
      content: '${unicode(2713)}';
    }

    @media ${from(Device.Tablet)} {
      height: 30px;
      flex: 0 0 30px;
      font-size: 22px;
      margin-top: 2px;
      width: 30px;
    }

    ${modalStyles.Container} & {
      border-color: ${theme.brand.blue.dark};
      color: ${theme.brand.blue.dark};
      margin-top: 0;
    }
  }
`;

const LabelText = styled.span`
  display: inline-block;
  color: ${theme.brand.white.default};
  ${theme.fonts.brandon.bold};
  font-size: 14px;
  line-height: 1.5em;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 25px;
    text-transform: none;
  }

  ${modalStyles.Container} & {
    color: ${theme.brand.blue.dark};
    font-size: 20px;
    line-height: 30px;
  }
`;

const Wrapper = styled.div<{ invalid: boolean }>`
  user-select: none;

  ${({ invalid }) =>
    invalid &&
    css`
      ${Label}::before {
        border-color: ${theme.brand.red.dark};
        color: ${theme.brand.red.dark};
      }

      ${LabelText} {
        color: ${theme.brand.red.dark};
      }
    `}
`;

export default {
  Input,
  Label,
  LabelText,
  Wrapper,
};
