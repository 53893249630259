import React from 'react';
import s from './CheckboxSingle.styles';

export interface CheckboxSingleProps extends Omit<ReactInputProps, 'type'> {
  hasError: boolean;
  label?: string;
}

const CheckboxSingle = React.forwardRef<HTMLInputElement, CheckboxSingleProps>(
  ({ hasError, id, label, ...otherProps }, ref) => {
    return (
      <s.Wrapper invalid={hasError}>
        <s.Input ref={ref} id={id} {...otherProps} type="checkbox" />
        <s.Label htmlFor={id}>
          <s.LabelText>{label}</s.LabelText>
        </s.Label>
      </s.Wrapper>
    );
  },
);

CheckboxSingle.displayName = 'CheckboxSingle';

export default CheckboxSingle;
