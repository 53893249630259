import { Device, from } from '@utils/media';
import styled from 'styled-components';
import buttonStyles from '@presentation/Button/Button.styles';
import textBoxStyles from '@presentation/FormControls/Textbox/TextBox.styles';
import srOnly from '@utils/srOnly';
import theme from '@utils/theme';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  margin: 0 auto 32px;
  max-width: 850px;
`;

const FormField = styled.div`
  display: grid;
  margin-bottom: 16px;
  width: 100%;

  ${textBoxStyles.Input} {
    grid-column: 1;
    grid-row: 1;
    padding-right: 112px;

    @media ${from(Device.Tablet)} {
      padding-right: 200px;
    }
  }

  ${buttonStyles.Button} {
    grid-column: 1;
    grid-row: 1;
    margin: auto 16px auto auto;

    @media ${from(Device.Tablet)} {
      margin: auto 32px auto auto;
    }
  }
`;

const FormLabel = styled.label`
  ${srOnly}
`;

const Honeypot = styled.input`
  ${srOnly};
`;

const FormContent = styled.div`
  margin-top: 24px;

  a {
    color: ${theme.brand.blue.dark};
  }
`;

export default {
  Form,
  FormContent,
  FormField,
  FormLabel,
  Honeypot,
};
