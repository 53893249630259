import IconBasket from '!babel-loader!react-svg-loader!@img/icon-basket.svg';
import BasketNotification, { BasketNotificationProps } from '@presentation/BasketNotification';
import Navicon from '@presentation/Navicon';
import Navigation from '@presentation/Navigation';
import { NavigationItemModel } from '@presentation/Navigation/NavigationItem';
import NotificationPanel, { NotificationPanelProps } from '@presentation/NotificationPanel';
import { toggleBasket } from '@redux/reducers/shopify';
import { useAppDispatch } from '@redux/store';
import imageUrl from '@utils/cloudinary';
import { useLocation } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { useBoolean, useWindowScroll } from 'react-use';
import s from './Header.styles';

export interface HeaderProps {
  basketNotification: BasketNotificationProps;
  cartCount: number;
  defaultNavigationImage: FluidObject;
  /** The amount of pixels to scroll before reducing header. */
  hideThreshold?: number;
  itemsPrimary: NavigationItemModel[];
  itemsSecondary: NavigationItemModel[];
  logo?: FluidObject;
  notification: NotificationPanelProps;
}

const Header: React.FC<HeaderProps> = ({
  basketNotification,
  cartCount,
  defaultNavigationImage,
  hideThreshold = 100,
  itemsPrimary,
  itemsSecondary,
  logo,
  notification,
}) => {
  const [small, setSmall] = useState(false);
  const [isNavigationActive, toggleNavigation] = useBoolean(false);
  const { y: scrollY } = useWindowScroll();

  const location = useLocation();

  useEffect(() => {
    toggleNavigation(false);
  }, [location.key]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSmall(scrollY > hideThreshold);
  }, [scrollY]);

  const isNotificationVisible = !!(notification.content1 || notification.content2);

  return (
    <>
      <NotificationPanel {...notification} />
      <s.LogoWrapper
        notification={isNotificationVisible && !isNavigationActive}
        small={small || isNavigationActive}
      >
        <s.SiteWide>
          <s.Inner>
            <GatsbyLink to="/">
              {logo && (
                <s.Logo>
                  <GatsbyImage fluid={logo} loading="eager" />
                </s.Logo>
              )}
            </GatsbyLink>
          </s.Inner>
        </s.SiteWide>
      </s.LogoWrapper>
      <Navigation
        active={isNavigationActive}
        defaultImage={defaultNavigationImage}
        itemsPrimary={itemsPrimary}
        itemsSecondary={itemsSecondary}
      />
      <s.CtaWrapper
        notification={isNotificationVisible && !isNavigationActive}
        small={small || isNavigationActive}
      >
        <s.SiteWide>
          <s.Inner>
            <s.CtaContainer>
              <s.CtaNavigation active={isNavigationActive} onClick={handleNaviconClick}>
                <s.CtaText>Menu</s.CtaText>
                <s.CtaTextActive>Close</s.CtaTextActive>
                <s.CtaIcon aria-hidden="true">
                  <Navicon active={isNavigationActive} />
                </s.CtaIcon>
              </s.CtaNavigation>
              <s.CtaBasket
                active={!!cartCount}
                onClick={handleBasketClick}
                data-cart-count={cartCount > 9 ? '~' : cartCount}
              >
                <s.CtaText>Basket</s.CtaText>
                <s.CtaIcon aria-hidden="true">
                  <IconBasket />
                </s.CtaIcon>
              </s.CtaBasket>
              <BasketNotification {...basketNotification} />
            </s.CtaContainer>
          </s.Inner>
        </s.SiteWide>
      </s.CtaWrapper>
    </>
  );

  function handleBasketClick(event: React.SyntheticEvent): void {
    event.preventDefault();
    dispatch(toggleBasket(true));
  }

  function handleNaviconClick(event: React.SyntheticEvent): void {
    event.preventDefault();
    toggleNavigation();
  }
};

export default Header;

export function headerLogo(publicId: string): FluidObject {
  return {
    aspectRatio: 398 / 127,
    sizes: '(max-width: 1023px) 250px, 398px',
    src: imageUrl(publicId, 'c_fill,g_auto,w_398,ar_398:127'),
    srcSet: `
      ${imageUrl(publicId, 'c_fill,g_auto,w_250,ar_398:127')} 250w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_398,ar_398:127')} 398w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_500,ar_398:127')} 500w,
      ${imageUrl(publicId, 'c_fill,g_auto,w_796,ar_398:127')} 796w
    `,
  };
}
