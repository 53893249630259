import React, { ReactNode } from 'react';

import s from './Button.styles';

export interface ButtonProps extends ReactButtonProps {
  children: ReactNode;
  small?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ children, small = false, ...otherProps }) => {
  return (
    <>
      <s.ClipPath>
        <clipPath id="button-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0.978,0 H0.021 c-0.004,0,-0.006,0.008,-0.007,0.021 L0.013,0.044 C0.013,0.048,0.013,0.049,0.012,0.052 c0,0,0,0.001,0,0.001 L0.006,0.058 C0.002,0.061,0,0.07,0,0.082 v0.835 c0,0.011,0.002,0.023,0.006,0.024 l0.006,0.006 c0,0,0,0,0,0.001 c0,0.001,0.001,0.004,0.001,0.007 l0.001,0.024 c0,0.013,0.004,0.021,0.007,0.021 h0.957 c0.004,0,0.007,-0.008,0.007,-0.021 l0.001,-0.024 c0,-0.003,0,-0.006,0.001,-0.007 c0,0,0,-0.001,0,-0.001 l0.006,-0.004 c0.003,-0.003,0.006,-0.013,0.006,-0.024 V0.082 c0,-0.011,-0.002,-0.023,-0.006,-0.024 l-0.006,-0.006 c0,0,0,0,0,-0.001 c0,-0.001,-0.001,-0.004,-0.001,-0.007 l-0.001,-0.024 C0.985,0.008,0.982,0,0.978,0 L0.978,0"></path>
        </clipPath>
      </s.ClipPath>
      <s.Button as="button" small={small} {...otherProps}>
        <s.ButtonOuter>
          <s.ButtonInner>
            <s.ButtonText>{children}</s.ButtonText>
          </s.ButtonInner>
        </s.ButtonOuter>
      </s.Button>
    </>
  );
};

export interface LinkProps extends ReactAnchorProps {
  children: ReactNode;
  small?: boolean;
}

export const Link: React.FC<LinkProps> = ({ children, href, small = false, ...otherProps }) => {
  const internal = href && /^\/(?!\/)/.test(href);

  if (internal && href) {
    return (
      <>
        <s.ClipPath>
          <clipPath id="button-clip-path" clipPathUnits="objectBoundingBox">
            <path d="M0.978,0 H0.021 c-0.004,0,-0.006,0.008,-0.007,0.021 L0.013,0.044 C0.013,0.048,0.013,0.049,0.012,0.052 c0,0,0,0.001,0,0.001 L0.006,0.058 C0.002,0.061,0,0.07,0,0.082 v0.835 c0,0.011,0.002,0.023,0.006,0.024 l0.006,0.006 c0,0,0,0,0,0.001 c0,0.001,0.001,0.004,0.001,0.007 l0.001,0.024 c0,0.013,0.004,0.021,0.007,0.021 h0.957 c0.004,0,0.007,-0.008,0.007,-0.021 l0.001,-0.024 c0,-0.003,0,-0.006,0.001,-0.007 c0,0,0,-0.001,0,-0.001 l0.006,-0.004 c0.003,-0.003,0.006,-0.013,0.006,-0.024 V0.082 c0,-0.011,-0.002,-0.023,-0.006,-0.024 l-0.006,-0.006 c0,0,0,0,0,-0.001 c0,-0.001,-0.001,-0.004,-0.001,-0.007 l-0.001,-0.024 C0.985,0.008,0.982,0,0.978,0 L0.978,0"></path>
          </clipPath>
        </s.ClipPath>
        <s.Button small={small} to={href} {...otherProps}>
          <s.ButtonOuter>
            <s.ButtonInner>
              <s.ButtonText>{children}</s.ButtonText>
            </s.ButtonInner>
          </s.ButtonOuter>
        </s.Button>
      </>
    );
  }

  return (
    <>
      <s.ClipPath>
        <clipPath id="button-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0.978,0 H0.021 c-0.004,0,-0.006,0.008,-0.007,0.021 L0.013,0.044 C0.013,0.048,0.013,0.049,0.012,0.052 c0,0,0,0.001,0,0.001 L0.006,0.058 C0.002,0.061,0,0.07,0,0.082 v0.835 c0,0.011,0.002,0.023,0.006,0.024 l0.006,0.006 c0,0,0,0,0,0.001 c0,0.001,0.001,0.004,0.001,0.007 l0.001,0.024 c0,0.013,0.004,0.021,0.007,0.021 h0.957 c0.004,0,0.007,-0.008,0.007,-0.021 l0.001,-0.024 c0,-0.003,0,-0.006,0.001,-0.007 c0,0,0,-0.001,0,-0.001 l0.006,-0.004 c0.003,-0.003,0.006,-0.013,0.006,-0.024 V0.082 c0,-0.011,-0.002,-0.023,-0.006,-0.024 l-0.006,-0.006 c0,0,0,0,0,-0.001 c0,-0.001,-0.001,-0.004,-0.001,-0.007 l-0.001,-0.024 C0.985,0.008,0.982,0,0.978,0 L0.978,0"></path>
        </clipPath>
      </s.ClipPath>
      <s.Button as="a" small={small} {...otherProps}>
        <s.ButtonOuter>
          <s.ButtonInner>
            <s.ButtonText>{children}</s.ButtonText>
          </s.ButtonInner>
        </s.ButtonOuter>
      </s.Button>
    </>
  );
};
