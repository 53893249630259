import IconClose from '!babel-loader!react-svg-loader!@img/icon-close.svg';
import contentFrameStyles from '@presentation/ContentFrame/ContentFrame.styles';
import srOnly from '@utils/srOnly';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';

export interface ContainerProps {
  active: boolean;
}

const Container = styled.section<ContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    backdrop-filter: blur(2px);
    background: ${theme.brand.grey.default};
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    height: 100%;
    transition: opacity 0.3s ease-out;
    width: 100vw;
    z-index: 1;
  }

  ${({ active }) =>
    active &&
    css`
      pointer-events: all;

      &::before {
        opacity: 0.7;
      }

      ${Outer} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    `}
`;

const Outer = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  opacity: 0;
  place-content: center;
  place-items: center;
  transform: translate3d(0, 20px, 0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  z-index: 2;

  ${contentFrameStyles.FrameContentWrapper} {
    padding: 32px;
  }
`;

const Close = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;
  background: none;
  border: 0;
  color: ${theme.brand.blue.dark};
  height: 30px;
  padding: 0;
  width: 20px;
`;

const CloseText = styled.span`
  ${srOnly};
`;

const CloseIcon = styled(IconClose)``;

export default {
  Close,
  CloseIcon,
  CloseText,
  Container,
  Outer,
};
