import bgPaperTiled from '@img/paper-bg-tiled.jpg';
import SvgSugarcane from '!babel-loader!react-svg-loader!@img/sugarcane-01.svg';
import SvgTurtle from '!babel-loader!react-svg-loader!@img/turtle.svg';
import { Device, from, until } from '@utils/media';
import theme from '@utils/theme';
import styled, { css } from 'styled-components';
import scrollbar from '@utils/scrollbar';
import { markdownStyles } from '@utils/markdown';

export interface ContainerProps {
  active: boolean;
}

const Container = styled.section<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background: ${theme.brand.paper.default} url(${bgPaperTiled});
  background-repeat: repeat;
  flex-direction: column;
  height: 100vh;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  width: 100%;
  z-index: 500;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

const SvgSugarcane1 = styled(SvgSugarcane)`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  color: #1d1d1b;
  opacity: 0.1;
  transform: translate3d(-50%, -100%, 0) translate3d(-390px, -220px, 0) rotate(-240deg);
  transform-origin: center bottom;
  z-index: 1;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

const SvgSugarcane2 = styled(SvgSugarcane)`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  color: #1d1d1b;
  opacity: 0.1;
  transform: translate3d(50%, 0, 0) translate3d(280px, 240px, 0) rotate(-50deg);
  transform-origin: center bottom;
  z-index: 1;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  ${scrollbar};
  position: relative;
  flex: 1 1 100%;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  z-index: 2;

  @media ${from(Device.Tablet)} {
    padding: 144px 24px 64px;
  }
`;

const ContentWrapperInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 64px 24px;
`;

const Badge = styled.div`
  display: block;
  height: 198px;
  margin-bottom: 32px;
  width: 198px;
`;

const Title = styled.h2`
  display: block;
  font-size: 40px;
  font-weight: 400;
  line-height: 45px;
  margin-bottom: 32px;
  max-width: 1112px;
  text-align: center;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 75px;
    line-height: 90px;
  }
`;

const Turtle = styled(SvgTurtle)`
  display: block;
  height: 69px;
  margin-bottom: 48px;
  opacity: 0.5;
  width: 69px;
`;

const CallToAction = styled.div`
  margin-bottom: 32px;
`;

const Content = styled.div`
  ${markdownStyles};
  display: block;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin-bottom: 32px;
  max-width: 941px;
  text-align: center;
  text-transform: uppercase;
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: auto;
`;

export default {
  Badge,
  CallToAction,
  Container,
  Content,
  ContentWrapper,
  ContentWrapperInner,
  SocialLinks,
  SvgSugarcane1,
  SvgSugarcane2,
  Title,
  Turtle,
};
