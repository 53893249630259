import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { ComponentThemeTaxonomy, SectionFlush } from '.';

export type WrapperProps = {
  flush: SectionFlush;
  margin: boolean;
  overflow: boolean;
  padding: boolean;
  theme: ComponentThemeTaxonomy;
};

type ThemePalette = Record<ComponentThemeTaxonomy, string>;
type ThemeStyles = Record<ComponentThemeTaxonomy, FlattenSimpleInterpolation>;

const themeColors: ThemePalette = {
  [ComponentThemeTaxonomy.NONE]: 'transparent',
  [ComponentThemeTaxonomy.LIGHT_BLUE]: theme.brand.blue.light,
  [ComponentThemeTaxonomy.WHITE]: theme.brand.white.default,
};

const themeStyles: ThemeStyles = {
  [ComponentThemeTaxonomy.NONE]: css`
    --bg-color: ${themeColors[ComponentThemeTaxonomy.NONE]};
  `,
  [ComponentThemeTaxonomy.LIGHT_BLUE]: css`
    --bg-color: ${themeColors[ComponentThemeTaxonomy.LIGHT_BLUE]};
  `,
  [ComponentThemeTaxonomy.WHITE]: css`
    --bg-color: ${themeColors[ComponentThemeTaxonomy.WHITE]};
  `,
};

const Wrapper = styled.section<WrapperProps>`
  display: block;
  background: var(--bg-color);

  ${({ theme }: WrapperProps) => css`
    position: relative;
    ${themeStyles[theme]};

    /* When the section follows another section with a different theme, handle the transition. */
    .theme-${theme}:not(.no-fade) + &:not(.theme-${theme}) {
      --prev-bg-color: ${themeColors[theme]};

      &::before {
        content: '';
        position: absolute;
        top: -50px;
        left: 0;
        display: block;
        background: linear-gradient(to bottom, var(--prev-bg-color), var(--bg-color));
        height: 100px;
        width: 100%;
        z-index: 1;
      }

      /* From non-transparent theme to transparent theme, offset gradient onto transparent area. */
      &.theme-${ComponentThemeTaxonomy.NONE} {
        &::before {
          top: -1px;
        }
      }
    }

    /* From transparent theme to non-transparent theme, offset gradient onto transparent area. */
    .theme-${ComponentThemeTaxonomy.NONE}:not(.no-fade)
      + &:not(.theme-${ComponentThemeTaxonomy.NONE}) {
      &::before {
        top: -99px;
      }
    }
  `}

  ${({ margin }) =>
    margin &&
    css`
      ${Inner} {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    `}

  ${({ overflow }) =>
    !overflow &&
    css`
      ${Inner} {
        position: relative;
        overflow: hidden;
      }
    `}

  ${({ padding }) =>
    padding &&
    css`
      ${Inner} {
        padding-top: 64px;
        padding-bottom: 64px;

        @media ${from(Device.Tablet)} {
          padding-top: 128px;
          padding-bottom: 128px;
        }
      }
    `}

  ${({ flush }) =>
    flush === SectionFlush.TOP &&
    css`
      ${Inner} {
        margin-top: 0;
        padding-top: 0;
      }
    `}

  ${({ flush }) =>
    flush === SectionFlush.BOTTOM &&
    css`
      ${Inner} {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    `}
`;

export const Inner = styled.div`
  height: 100%;
`;

export const SiteWide = styled.div`
  --site-wide: 1636px;
  --site-padding: 18px;

  height: 100%;
  max-width: calc(var(--site-wide) + (var(--site-padding) * 2));
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  padding-left: var(--site-padding);
  padding-right: var(--site-padding);
  width: 100%;

  @media ${from(Device.Tablet)} {
    --site-padding: 24px;
  }
`;

export default {
  Inner,
  SiteWide,
  Wrapper,
};
