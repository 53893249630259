import { css, FlattenSimpleInterpolation } from 'styled-components';

export type Theme = {
  brand: {
    black: {
      default: string;
    };
    blue: {
      default: string;
      dark: string;
      light: string;
    };
    gold: {
      default: string;
    };
    grey: {
      default: string;
      dark: string;
    };
    paper: {
      default: string;
      dark: string;
    };
    red: {
      default: string;
      dark: string;
    };
    white: {
      default: string;
      off: string;
    };
  };
  fonts: {
    brandon: {
      thin: FlattenSimpleInterpolation;
      thinItalic: FlattenSimpleInterpolation;
      light: FlattenSimpleInterpolation;
      lightItalic: FlattenSimpleInterpolation;
      regular: FlattenSimpleInterpolation;
      italic: FlattenSimpleInterpolation;
      medium: FlattenSimpleInterpolation;
      mediumItalic: FlattenSimpleInterpolation;
      bold: FlattenSimpleInterpolation;
      boldItalic: FlattenSimpleInterpolation;
      black: FlattenSimpleInterpolation;
      blackItalic: FlattenSimpleInterpolation;
    };
    venusian: FlattenSimpleInterpolation;
  };
};

const theme: Theme = {
  brand: {
    black: {
      default: '#000000',
    },
    blue: {
      default: '#2067AD',
      dark: '#0E4D8F',
      light: '#E6EDF4',
    },
    gold: {
      default: '#E8AF5F',
    },
    grey: {
      default: '#707070',
      dark: '#1d1d1b',
    },
    paper: {
      default: '#F5EDD8',
      dark: '#b2aea3',
    },
    red: {
      default: '#E44A2F',
      dark: '#DF2D26',
    },
    white: {
      default: '#FFFFFF',
      off: '#F6F6F6',
    },
  },
  fonts: {
    brandon: {
      thin: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 100;
        font-style: normal;
      `,
      thinItalic: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 100;
        font-style: italic;
      `,
      light: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 300;
        font-style: normal;
      `,
      lightItalic: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 300;
        font-style: italic;
      `,
      regular: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 400;
        font-style: normal;
      `,
      italic: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 400;
        font-style: italic;
      `,
      medium: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 500;
        font-style: normal;
      `,
      mediumItalic: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 500;
        font-style: italic;
      `,
      bold: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 700;
        font-style: normal;
      `,
      boldItalic: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 700;
        font-style: italic;
      `,
      black: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 900;
        font-style: normal;
      `,
      blackItalic: css`
        font-family: 'brandon-grotesque', sans-serif;
        font-weight: 900;
        font-style: italic;
      `,
    },
    venusian: css`
      font-family: 'venusian', sans-serif;
      font-weight: 400;
      font-style: normal;
    `,
  },
};

export default theme;
