import React from 'react';
import SvgIconFacebook from '!babel-loader!react-svg-loader!@img/icon-facebook.svg';
import SvgIconInstagram from '!babel-loader!react-svg-loader!@img/icon-instagram.svg';
import s from './SocialLink.styles';

export interface SocialLinkProps {
  id: string;
  icon: string;
  url: string;
}

const SocialLink: React.FC<SocialLinkProps> = ({ icon, url }) => {
  const Icon = getIcon();

  if (!Icon) {
    return null;
  }

  return (
    <s.Link href={url} rel="noreferrer noopener" target="_blank">
      <Icon />
    </s.Link>
  );

  function getIcon(): React.FC {
    switch (icon) {
      case 'facebook':
        return SvgIconFacebook;

      case 'instagram':
        return SvgIconInstagram;

      default:
        throw new Error('');
    }
  }
};

export default SocialLink;
