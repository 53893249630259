import SvgStamp from '!babel-loader!react-svg-loader!@img/sot-stamp.svg';
import { markdownStyles } from '@utils/markdown';
import { Device, from, until } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export interface WrapperProps {
  reverse: boolean;
}

const Wrapper = styled(motion.div)<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${from(Device.TabletLarge)} {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(12, 1fr);
    place-items: center;
    text-align: left;

    ${({ reverse }) => css`
      ${ContentWrapper} {
        ${reverse
          ? css`
              grid-column: 7 / span 6;

              @media ${from(Device.Desktop)} {
                grid-column: 7 / span 5;
              }
            `
          : css`
              grid-column: 1 / span 6;

              @media ${from(Device.Desktop)} {
                grid-column: 2 / span 5;
              }
            `}
      }

      ${ProductWrapper} {
        ${reverse
          ? css`
              grid-column: 1 / span 6;

              @media ${from(Device.Desktop)} {
                grid-column: 1 / span 5;
              }
            `
          : css`
              grid-column: 7 / span 6;

              @media ${from(Device.Desktop)} {
                grid-column: 8 / span 5;
              }
            `}
      }

      ${Stamp} {
        ${reverse
          ? css`
              display: none;
            `
          : css`
              display: block;
            `}
      }
    `}
  }
`;

const Stamp = styled(SvgStamp)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${theme.brand.red.default};
  width: ${(373 / 1920) * 100}%;

  @media ${until(Device.TabletLarge)} {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  grid-row: 1;
  margin-bottom: 32px;

  @media ${from(Device.TabletLarge)} {
    margin-top: 80px;
    margin-bottom: 0;
  }
`;

const ProductWrapper = styled(motion.div)`
  grid-row: 1;
  margin: 0 auto;
  max-width: 640px;
  text-align: center;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Title = styled(motion.h2)`
  color: ${theme.brand.red.default};
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 24px;
  }
`;

const Subtitle = styled(motion.h3)`
  color: ${theme.brand.blue.dark};
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin-bottom: 8px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 28px;
    margin-bottom: 32px;
  }
`;

const CallToActions = styled(motion.div)`
  display: flex;
  flex-direction: column;
  place-items: center;

  @media ${from(Device.TabletLarge)} {
    flex-direction: row;
    place-items: center;
  }
`;

const CallToAction1 = styled(motion.div)``;

const CallToAction2 = styled(motion.div)`
  margin-top: 16px;

  @media ${from(Device.TabletLarge)} {
    margin-top: 0;
    margin-left: 24px;
  }
`;

const ProductImage = styled.div`
  max-width: 420px;
  margin: 0 auto 16px;
`;

const ProductTitle = styled.h3`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.venusian};
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 16px;
  }
`;

const ProductMeta = styled.span`
  display: flex;
  flex-direction: row;
  place-content: center;
  place-items: flex-end;
`;

const ProductPrice = styled.span`
  color: ${theme.brand.red.default};
  ${theme.fonts.venusian};
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 35px;
    letter-spacing: 0.02em;
    line-height: 40px;
  }
`;

const ProductOption = styled.span`
  color: ${theme.brand.blue.dark};
  ${theme.fonts.venusian};
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 20px;
  margin-left: 8px;
  margin-bottom: 3px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 28px;
    margin-left: 8px;
    margin-bottom: 2px;
  }
`;

export default {
  CallToActions,
  CallToAction1,
  CallToAction2,
  Content,
  ContentWrapper,
  ProductImage,
  ProductMeta,
  ProductOption,
  ProductPrice,
  ProductTitle,
  ProductWrapper,
  Stamp,
  Subtitle,
  Title,
  TitleWrapper,
  Wrapper,
};
