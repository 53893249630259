import React from 'react';
import s from './CarouselButton.styles';

export interface CarouselButtonProps extends Omit<ReactButtonProps, 'type'> {
  direction: 'next' | 'prev';
  size: 'medium';
}

const CarouselButton: React.FC<CarouselButtonProps> = ({ direction, size, ...otherProps }) => {
  return (
    <s.Wrapper direction={direction} size={size} type="button" {...otherProps}>
      <s.Label>{direction}</s.Label>
      <s.Icon />
    </s.Wrapper>
  );
};

export default CarouselButton;
