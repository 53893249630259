import FooterPres, { FooterLinkModel, image, imageAlt } from '@presentation/Footer';
import { isMenuItem } from '@utils/guards';
import { Device, from } from '@utils/media';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { useMedia } from 'react-use';
import { Contentful } from 'schema/Contentful';
import { ContentModels } from 'schema/ContentModels';

export interface FooterProps {
  alternate: boolean;
}

const Footer: React.FC<FooterProps> = ({ alternate }) => {
  const data = useFooterQuery();

  const isDesktop = useMedia(from(Device.Tablet), false);

  const footerBackgroundImage =
    alternate && isDesktop
      ? imageAlt(data.master.footerBackgroundImageAlt?.[0])
      : image(data.master.footerBackgroundImage?.[0]);

  return (
    <FooterPres
      alternate={alternate}
      backgroundImage={footerBackgroundImage}
      items1={createItemArray(data.master.footerNavigation1)}
      items2={createItemArray(data.master.footerNavigation2)}
      items3={createItemArray(data.master.footerNavigation3)}
      legalItems={createItemArray(data.master.footerLegalNavigation)}
      newsletter={{
        checkboxLabel: data.master.newsletterFormCheckboxLabel,
        confirmationContent:
          data.master.newsletterFormConfirmationContent?.newsletterFormConfirmationContent ?? '',
        formContent: data.master.marketingCheckboxContent?.marketingCheckboxContent,
        subtitle: data.master.newsletterFormSubtitle,
        title: data.master.newsletterFormTitle,
      }}
    />
  );
};

export default Footer;

function createItemArray(entries?: Contentful.ContentfulEntry[]): FooterLinkModel[] {
  const menuItems = entries?.filter(isMenuItem) ?? [];
  return menuItems.map(createItemModel);
}

function createItemModel(entry: ContentModels.ContentfulMenuItem): FooterLinkModel {
  return {
    id: entry.id ?? '',
    title: entry.title ?? '',
    url: entry.linkExternal ?? entry.linkInternal?.url,
  };
}

interface FooterQuery {
  master: ContentModels.ContentfulMaster;
}

function useFooterQuery(): FooterQuery {
  return useStaticQuery<FooterQuery>(graphql`
    query FooterQuery {
      master: contentfulMaster {
        footerLegalNavigation {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          ... on ContentfulMenuItem {
            image
            linkExternal
            linkInternal {
              id
              ... on NodeWithUrl {
                url
              }
            }
            title
          }
        }
        footerNavigation1 {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          ... on ContentfulMenuItem {
            image
            linkExternal
            linkInternal {
              id
              ... on NodeWithUrl {
                url
              }
            }
            title
          }
        }
        footerNavigation2 {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          ... on ContentfulMenuItem {
            image
            linkExternal
            linkInternal {
              id
              ... on NodeWithUrl {
                url
              }
            }
            title
          }
        }
        footerNavigation3 {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          ... on ContentfulMenuItem {
            image
            linkExternal
            linkInternal {
              id
              ... on NodeWithUrl {
                url
              }
            }
            title
          }
        }
        footerBackgroundImage
        footerBackgroundImageAlt
        marketingCheckboxContent {
          marketingCheckboxContent
        }
        newsletterFormCheckboxLabel
        newsletterFormConfirmationContent {
          newsletterFormConfirmationContent
        }
        newsletterFormSubtitle
        newsletterFormTitle
      }
    }
  `);
}
