import NewsletterSignUpModalPres from '@presentation/NewsletterSignUpModal';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

const NewsletterSignUpModal: React.FC = () => {
  const data = useNewsletterSignUpModalQuery();

  return (
    <NewsletterSignUpModalPres
      checkboxLabel={data.master.newsletterFormCheckboxLabel ?? ''}
      confirmationContent={
        data.master.exitIntentConfirmationContent?.exitIntentConfirmationContent ?? ''
      }
      content={data.master.exitIntentContent?.exitIntentContent ?? ''}
      formContent={data.master.marketingCheckboxContent?.marketingCheckboxContent}
      title={data.master.exitIntentTitle ?? ''}
    />
  );
};

export default NewsletterSignUpModal;

interface NewsletterSignUpModalQuery {
  master: ContentModels.ContentfulMaster;
}

function useNewsletterSignUpModalQuery(): NewsletterSignUpModalQuery {
  return useStaticQuery<NewsletterSignUpModalQuery>(graphql`
    query NewsletterSignUpModalQuery {
      master: contentfulMaster {
        exitIntentConfirmationContent {
          exitIntentConfirmationContent
        }
        exitIntentContent {
          exitIntentContent
        }
        exitIntentTitle
        marketingCheckboxContent {
          marketingCheckboxContent
        }
        newsletterFormCheckboxLabel
      }
    }
  `);
}
