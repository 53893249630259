import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ShopifyBuy } from 'shopify';

import addToCart from './thunks/addToCart';
import initialiseCart from './thunks/fetchCart';
import removeFromCart from './thunks/removeFromCart';
import updateInCart from './thunks/updateInCart';

export type ShopifyState = {
  checkout?: ShopifyBuy.Cart;
  currentRequestId?: string;
  error?: SerializedError;
  lastAdded?: {
    id: string;
    variantId: string;
    quantity: number;
  };
  loading: 'idle' | 'pending';
  showBasket: boolean;
};

const initialState: ShopifyState = {
  loading: 'idle',
  showBasket: false,
};

const shopifySlice = createSlice({
  name: 'shopify',
  initialState,
  reducers: {
    toggleBasket(state, action: PayloadAction<boolean>) {
      state.showBasket = action.payload;
    },
  },
  extraReducers: (builder) => {
    // addToCart

    builder.addCase(addToCart.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(addToCart.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.checkout = action.payload;
        state.currentRequestId = undefined;
        state.lastAdded = { id: requestId, quantity: arg.quantity, variantId: arg.variantId };
      }
    });

    builder.addCase(addToCart.rejected, (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });

    // / addToCart

    // initialiseCart

    builder.addCase(initialiseCart.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(initialiseCart.fulfilled, (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.checkout = action.payload;
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(initialiseCart.rejected, (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });

    // / initialiseCart

    // removeFromCart

    builder.addCase(removeFromCart.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(removeFromCart.fulfilled, (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.checkout = action.payload;
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(removeFromCart.rejected, (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });

    // / removeFromCart

    // updateInCart

    builder.addCase(updateInCart.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(updateInCart.fulfilled, (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.checkout = action.payload;
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(updateInCart.rejected, (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });

    // / updateInCart
  },
});

const { actions, reducer } = shopifySlice;

export const { toggleBasket } = actions;

export default reducer;
