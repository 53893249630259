import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ShopifyBuy } from 'shopify';
import client from '../client';

type RemoveFromCartParams = { lineItemId: string };

type ThunkApiType = {
  state: RootState;
};

const TypePrefix = 'shopify/removeFromCart';

type ThunkType = AsyncThunk<ShopifyBuy.Cart | undefined, RemoveFromCartParams, ThunkApiType>;

const removeFromCart: ThunkType = createAsyncThunk(TypePrefix, async ({ lineItemId }, thunkApi) => {
  const state = thunkApi.getState();

  const checkoutId = state.shopify.checkout?.id;

  if (!checkoutId) {
    return undefined;
  }

  const cart = await client.checkout.removeLineItems(checkoutId, [lineItemId]);

  // Ensure we return a plain object.
  return JSON.parse(JSON.stringify(cart));
});

export default removeFromCart;
